<template>
  <div class="v-stack h-stretch gap-3">
    <Table :items="data" :defaultColumn="0" v-on:rowSelected="onclick($event)"
      :css="'100px 180px 1fr 40px 70px 80px 80px 100px'" :columns="[
        {
          name: 'Date',
          sort: 'date',
          path: ['day'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Name',
          sort: 'alphabet',
          path: ['user', 'fullName'],
        },
        {
          name: 'Time / Description',
          sort: 'alphabet',
          formatMap: (workday) =>
            workday.oneTimePay
              ? workday.description
              : `${formatDate(workday.start, 'HH:mm')} - ${formatDate(
                workday.end,
                'HH:mm'
              )}`,
        },
        {
          name: 'Hours',
          sort: 'alphabet',
          formatMap: (workday) =>
            workday.oneTimePay ? '' : `${sumWorkhours(workday.workhours)} h`,
        },
        {
          name: 'Money',
          sort: 'alphabet',
          formatMap: (workday) =>
            `${workday.oneTimePay
              ? Math.round(workday.amount)
              : calculateMoney(workday.workhours)
            }`,
          formatType: 'currency',
          format: 'czk',
        },
        {
          name: 'Created By',
          sort: 'alphabet',
          path: ['createdBy', 'fullName'],
        },
        {
          name: 'Signed By',
          sort: 'alphabet',
          path: ['reviewedBy', 'fullName'],
        },
        {
          name: 'Signed',
          alignTitle: 'center',
        },
      ]">
      <template v-slot:Signed="{ item }">
        <div class="h-stack h-center">
          <div v-if="item.state == 'signed'">
            <i class="fas fa-check fa-lg text-success"></i>
          </div>
          <div v-if="item.state == 'canceled'">
            <i class="fas fa-times fa-2x text-error"></i>
          </div>
          <div class="h-stack gap-5" v-if="allowSign && item.state == 'created'">
            <div @click.stop="cancel(item._id, item.oneTimePay)">
              <i class="fas fa-times fa-2x text-error"></i>
            </div>
            <div @click.stop="sign(item._id, item.oneTimePay)" class="ma">
              <i class="fas fa-check fa-lg text-success"></i>
            </div>
          </div>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const moment = require("moment");
import Table from "@/components/Table.vue";
import { v4 as uuid } from "uuid";

export default {
  data() {
    return {
      page: 0,
      maxPageShortcut: 7,
    };
  },
  components: {
    Table,
  },
  props: {
    pageable: {
      type: Boolean,
      default: false,
    },
    allowSign: {
      type: Boolean,
      default: false,
    },
    showName: {
      type: Boolean,
      default: false,
    },
    user: {
      type: String,
      default: null,
    },
    workdays: {
      type: Array,
      default: () => [],
    },
    oneTimePay: {
      type: Array,
      default: () => [],
    },
    emptyMessage: {
      type: String,
      default: "No Data",
    },
    showWorkHours: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    workdays() {
      this.page = 0;
    },
    oneTimePay() {
      this.page = 0;
    },
  },
  computed: {
    height() {
      if (this.pageable) {
        return 720;
      }
      return null;
    },
    limit() {
      if (this.pageable) {
        return 20;
      }
      return Number.MAX_SAFE_INTEGER;
    },
    data() {
      // cache workdays
      let workdays = this.workdays.slice();

      for (const workday of workdays) {
        workday.tableColumns = {}

        workday.tableColumns['Name'] = []
        workday.tableColumns['Time / Description'] = []
        workday.tableColumns['Hours'] = []
        workday.tableColumns['Money'] = []
        if (this.showWorkHours) {
          for (const workhour of workday.workhours) {
            workday.tableColumns['Name'].push({
              _id: uuid(),
              text: workhour.project.name
            })
            workday.tableColumns['Time / Description'].push({
              _id: uuid(),
              text: workhour.comment || "No Description"
            })
            workday.tableColumns['Hours'].push({
              _id: uuid(),
              text: workhour.hours + " h"
            })
            workday.tableColumns['Money'].push({
              _id: uuid(),
              text: Math.round(workhour.hours * workhour.pay.amount) + " Kč"
            })
          }
        }
      }

      // cache onetimepay
      let oneTimePay = this.oneTimePay.map((item) => {
        return {
          _id: item._id,
          oneTimePay: true,
          amount: item.amount,
          day: item.date,
          start: item.date,
          state: item.state,
          description: item.description,
          user: item.user,
          createdBy: item.createdBy,
          reviewedBy: item.reviewedBy,
        };
      });

      let data = workdays.concat(oneTimePay);

      data.sort((a, b) => {
        const dateA = moment(a.day)
          .hour(moment(a.start).hour())
          .minute(moment(a.start).minute());
        const dateB = moment(b.day)
          .hour(moment(b.start).hour())
          .minute(moment(b.start).minute());

        if (moment(dateA).isBefore(moment(dateB))) {
          return -1;
        } else if (moment(dateA).isAfter(moment(dateB))) {
          return 1;
        } else {
          return 0;
        }
      });

      return data;
    },
  },
  methods: {
    ...mapActions([
      "signWorkday",
      "cancelWorkday",
      "signOneTimePay",
      "cancelOneTimePay",
    ]),
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
    sumWorkhours(workhours) {
      let result = 0;
      for (const workhour of workhours) {
        result += workhour.hours;
      }
      return result;
    },
    calculateMoney(workhours) {
      let result = 0;
      for (const workhour of workhours) {
        result += workhour.hours * workhour.pay.amount;
      }
      return Math.round(result);
    },
    onclick(event) {
      if (Array.isArray(event.workhours)) {
        this.$router.push("/workday/" + event._id)
      }
    },
    sign(workdayId, oneTimePay) {
      if (oneTimePay) {
        this.signOneTimePay(workdayId)
          .then(() => {
            this.$emit("refresh");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.signWorkday(workdayId)
          .then(() => {
            this.$emit("refresh");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    cancel(workdayId, oneTimePay) {
      if (oneTimePay) {
        this.cancelOneTimePay(workdayId)
          .then(() => {
            this.$emit("refresh");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.cancelWorkday(workdayId)
          .then(() => {
            this.$emit("refresh");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.workday-row {
  grid-auto-columns: 100px 1fr 60px 60px 100px !important;
  gap: 10px;
}

.workday-row-name {
  grid-auto-columns: 100px 180px 1fr 60px 60px 100px !important;
  gap: 10px;
}

.columns-3 {
  grid-auto-columns: 100px 1fr 100px !important;

  div {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }
}

.table-row div {
  white-space: nowrap;
  overflow: hidden;
}

.table-container {
  height: 750px;
}

.active-page {
  font-weight: bold;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

i.disabled {
  color: lightgray;
}

div {
  align-self: center;
}
</style>
