import Vue from "vue";

export default {
  actions: {
    addGasPrice({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .post("/cars/gas", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getGasPrice({ dispatch }) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/cars/gas")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    addTrip({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .post("/cars/day", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    editTrip({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .patch("/cars/day/" + data._id, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getTrip({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/cars/day/" + id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getCars({ dispatch }) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/cars")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getCar({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/cars/" + id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    addCar({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .post("/cars", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    editCar({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .patch("/cars/" + data._id, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    deleteCar({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        Vue.api
          .delete("/cars/" + id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
  },
};
