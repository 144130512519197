export default [
    {
        path: "/clients",
        props: true,
        name: "Clients",
        component: () => import("../views/clients/Clients.vue")
    },
    {
        path: "/clients/add",
        name: "Add Client",
        component: () => import("../views/clients/AddClient.vue")
    },
    {
        path: "/clients/detail/:id",
        props: true,
        name: "Show Client",
        component: () => import("../views/clients/ClientDetail.vue")
    },
    {
        path: "/clients/edit/:id",
        props: true,
        name: "Edit Client",
        component: () => import("../views/clients/EditClient.vue")
    },
]