<template>
  <div id="app" ref="app" class="">
    <div class="v-stack notification-popup" v-bind:class="[notifications.length > 0 ? 'v-start' : 'v-stretch']"
      v-if="showNotifications">
      <div v-bind:class="[notification.state == 'read' ? '' : 'notification-bold']"
        class="notification-row v-stack h-stretch" v-for="notification in notifications" :key="notification._id"
        @click="handleNotificationClick(notification)">
        <label class="text-left">{{ formatDate(notification.date,
            "HH:mm - DD.MM.YYYY")
        }}</label>
        <div class="text-left">{{
            notificationObjects[notification.type].text(notification.data,
              notification.instigator)
        }}</div>
      </div>
      <div v-if="notifications.length == 0" style="align-self: center">No Notifications</div>
    </div>

    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-5">
            <div>Are you sure you want to logout?</div>
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismissLogoutDialog()" class="submit">Cancel</button>
          <button @click="handleLogout()" class="edit">Logout</button>
        </div>
      </div>
    </div>

    <div ref="notification" class="notification"></div>

    <div ref="search" class="overlay">
      <Search v-on:dismiss="dismiss()"></Search>
    </div>

    <div class="navbar shadow h-space-between">
      <div class="h-stack">
        <i class="fas fa-bars" v-if="isMobile()" @click="showMenu = !showMenu"></i>
        <div class="logo" @click="$router.push('/')">lifeMANAGER</div>
        <template v-if="!isMobile()">
          <div class="breadcrumb">
            <div v-for="(page, index) in $store.state.path" :key="page.path" v-bind:class="[
              index == $store.state.path.length - 1 ? 'disabled' : '',
            ]" @click="
  index != $store.state.path.length - 1
    ? $router.push(page.path)
    : null
">
              {{ page.name }}
            </div>
          </div>
        </template>
      </div>
      <template v-if="!isMobile()">
        <div class="h-stack gap-3" v-if="this.$route.path != '/login'">
          <div @click="showSearch()" class="inline">
            <i class="fas fa-search"></i>
          </div>
          <button v-if="$store.state.accessLevel > 4 && $store.state.accessLevel < 10" class="add"
            @click="$router.push('/users/workday/new/' + $store.state.id)">
            Add Workday
          </button>
          <button class="dropdown add" v-if="$store.state.accessLevel == 10">
            <div style="color: white !important">Add Workday</div>
            <div class="dropdown-content">
              <button v-for="user in crew" :key="user._id" @click="$router.push('/users/workday/new/' + user._id)">
                {{ user.fullName }}
              </button>
            </div>
          </button>
          <div class="inline">
            <i class="far fa-comment fa-lg"></i>
          </div>
          <div class="inline">
            <i class="far fa-bell fa-lg notification-icon" @click="showNotifications = !showNotifications">
              <div v-if="numberOfUnreadNotifications > 0" class="notification-badge">{{ numberOfUnreadNotifications }}
              </div>
            </i>
          </div>
          <a @click="$router.push('/account')" style="align-self: center">{{
              $store.state.name || "Please relog"
          }}</a>

          <div class="inline" @click="showLogoutDialog()">
            <i class="fas fa-sign-out-alt fa-lg"></i>
          </div>
          <img src="/assets/images/icon_cz.png" width="30" height="30" />
        </div>
      </template>
      <div class="h-stack gap-3" v-if="isMobile()">
        <button v-if="$store.state.accessLevel > 4" class="add"
          @click="$router.push('/users/workday/new/' + $store.state.id)">
          +
        </button>
        <div class="inline" @click="showLogoutDialog()">
          <i class="fas fa-sign-out-alt fa-lg"></i>
        </div>
      </div>
    </div>

    <template v-if="!isMobile() || showMenu">
      <div class="v-stack v-start gap-3 menu" v-if="this.$route.path != '/login'">
        <router-link v-for="link in links.filter(
          (link) => $store.state.accessLevel >= link.accessLevel
        )" :key="link.path" :to="link.path" v-bind:class="[isActive(link.path) ? 'active-link' : '']">{{ link.name }}
        </router-link>
      </div>
    </template>

    <div class="nav v-stack h-stretch">
      <div id="content" v-bind:class="this.$route.path == '/login' || this.isMobile() ? 'no-menu' : ''"
        class="pane-top crop">
        <div class="v-stack h-stretch v-start">
          <router-view />
        </div>
        <div id="page-footer" class="footer v-stack v-end">
          <div>Copyright © 2021 - lifeREPORT Production s.r.o.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Settings } from "luxon";
import moment from "moment";
import EventBus from "@/eventbus.js";
import Vue from "vue";
import { io } from "socket.io-client";
import constants from "@/constants.js";

moment.locale("cs");
Settings.defaultLocale = "cs";

const style = require("@/style/style.js");

export default {
  data() {
    return {
      showNotifications: false,
      links: Vue.links,
      io: null,
      startTimestamp: moment().toISOString(),
      crew: [],
      showMenu: false,
      notifications: [],
      notificationObjects: constants.notificationObjects
    };
  },
  computed: {
    numberOfUnreadNotifications() {
      return this.notifications.filter((notification) => notification.state == "unread").length
    }
  },
  mounted() {
    this.refreshNotifications()

    if (this.$isMobile()) {
      this.$refs.app.classList.add("mobile")
    }

    document.addEventListener("mousedown", () => {
      if (this.showMenu) {
        setTimeout(() => {
          this.showMenu = false
        }, 100)
      }
    })

    if (this.$store.state.id == null) {
      this.$router.push("/login");
    } else {
      this.connectSockets(this.$store.state.token);
    }

    EventBus.$on("message", (data) => {
      const notification = this.$refs.notification;
      notification.classList.add("show");
      notification.innerHTML = data.text;
      notification.parentNode.replaceChild(notification, notification);
    });

    EventBus.$on("newToken", (token) => {
      this.connectSockets(token);
    });

    if (this.$store.state.accessLevel == 10) {
      this.getCrew().then((users) => {
        this.crew = users
          .sort((a, b) => a.fullName.localeCompare(b.fullName, "cs"));
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  methods: {
    ...mapActions(["logout", "getCrew", "markNotificationAsRead", "getNotifications"]),
    refreshNotifications() {
      this.getNotifications().then((notifications) => {
        this.notifications = notifications
      }).catch((error) => {
        console.log(error)
      })
    },
    handleNotificationClick(notification) {
      if (notification.state == "unread") {
        this.markNotificationAsRead(notification._id).then(() => {
          this.refreshNotifications()
        }).catch((error) => {
          console.log(error)
        })
      }

      const newRoute = this.notificationObjects[notification.type].link(notification.data,
        notification.instigator)
      if (this.$route.path != newRoute) {
        this.$router.push(newRoute)
      }

      this.showNotifications = false
    },
    isMobile() {
      return this.$isMobile()
    },
    connectSockets(token) {
      if (this.io) {
        this.io.disconnect();
      }

      if (token) {
        this.io = io({ query: { token: token } });
        this.io.on("connect", () => {
          // console.log("connected");
          this.setupSocketEvents(this.io);
        });
        // this.io.on("disconnect", () => {
        //   console.log("disconnected");
        // });
      }
    },
    setupSocketEvents(io) {
      io.on("newChatMessage", () => {
        EventBus.$emit("newChatMessage");
      });
      io.on("newNotification", () => {
        this.refreshNotifications()
      });
      io.on("serverStartTimestamp", (timestamp) => {
        if (moment(this.startTimestamp).isBefore(moment(timestamp))) {
          window.location.reload(true);
        }
      });
    },
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
    handleLogout() {
      this.dismissLogoutDialog();
      this.logout();
      this.$router.push("/login");
    },
    showSearch() {
      EventBus.$emit("refreshSearch");
      this.$refs.search.classList.add("show");
    },
    dismiss() {
      if (this.$refs.search.classList.contains("show")) {
        this.$refs.search.classList.remove("show");
      }
    },
    showLogoutDialog() {
      this.$refs.overlay.classList.add("show");
    },
    dismissLogoutDialog() {
      this.$refs.overlay.classList.remove("show");
    },
    isActive(path) {
      if (this.$store.state.path.length > 0) {
        if (path == this.$store.state.path[0].path) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        style.load();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-icon {
  position: relative;
}

.notification-badge {
  all: unset;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  position: absolute;
  bottom: -10px;
  right: -10px;
  background-color: red;
  color: white;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.notification-popup {
  z-index: 11;
  position: fixed;
  background-color: white;
  top: 71px;
  right: 0px;
  width: 400px;
  height: calc(100% - 71px);
  border-left: solid lightgrey 1px;
  user-select: none;
  overflow-y: auto;
}

.notification-bold {
  font-weight: bold;
}

.notification-row {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  line-height: normal;
  font-size: 16px;
  padding: 10px;
  border-bottom: solid lightgrey 1px;
  cursor: pointer;

  &:hover {
    background-color: lightgrey;
  }
}

.navbar div {
  align-self: center;
}

.footer {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: white;
}
</style>

<style lang="scss">
$small-breakpoint: 700px !default;

$navbar-height: 70px;
$panel-width: 400px;
$default-padding-x: 20px;
$default-padding-y: 40px;
$menu-width: 140px;
$crop-width: $menu-width + $default-padding-x + $default-padding-x + $default-padding-y + $default-padding-y;
$panel-height: $default-padding-y + $default-padding-y + $navbar-height;

@import "@/style/main.scss";

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";

.panel-content-center {
  @extend .v-stack;
  @extend .h-stretch;
  @extend .gap-3;
  margin-left: $panel-width;
  width: calc(100% - #{$panel-width} - #{$panel-width});
  overflow-x: auto;
}

.panel-content-full {
  @extend .v-stack;
  @extend .h-stretch;
  @extend .gap-3;
  width: calc(100%);
  overflow-x: auto;
}

.panel-content-left {
  @extend .v-stack;
  @extend .h-stretch;
  @extend .gap-3;
  width: calc(100% - #{$panel-width});
  overflow-x: auto;
}

.panel-content-right {
  @extend .v-stack;
  @extend .h-stretch;
  @extend .gap-3;
  margin-left: $panel-width;
  width: calc(100% - #{$panel-width});
  overflow-x: auto;
}

.dropdown-content {
  width: unset;
}

.dropdown-content button {
  width: 100% !important;
  text-align: left;
}

.panel-left {
  @extend .v-stack;
  @extend .h-stretch;
  @extend .gap-3;
  position: fixed;
  top: $navbar-height + $default-padding-y;
  left: $default-padding-x + $default-padding-x + $default-padding-x + $default-padding-x + $menu-width;
  width: $panel-width - $default-padding-x - $default-padding-x;
  height: calc(100vh - #{$panel-height});
  overflow-y: auto;
}

.panel-right {
  @extend .v-stack;
  @extend .h-stretch;
  @extend .gap-3;
  position: fixed;
  top: $navbar-height + $default-padding-y;
  right: $default-padding-x + $default-padding-x;
  width: $panel-width - $default-padding-x - $default-padding-x;
  height: calc(100vh - #{$panel-height});
  overflow-y: auto;
}

.inline {
  display: inline;
  padding-left: 6px;
  padding-right: 6px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}

#content {
  padding: $default-padding-y;
  margin-left: 180px;
  background-color: white;
  min-height: calc(100vh - #{$panel-height});
}

.no-menu {
  margin: 0px !important;
  width: calc(100% - #{$default-padding-y} * 2) !important;
}

.crop {
  max-width: 1000px;
  justify-self: center;
  width: calc(100% - #{$crop-width});
}

.logo {
  width: $menu-width;
  text-align: center;
  padding-right: $default-padding-x + $default-padding-x;
}

.menu {
  border: 1px solid lightgrey;
  width: $menu-width;
  margin-top: $navbar-height;
  padding: $default-padding-y $default-padding-x $default-padding-y $default-padding-x;
  position: fixed;
  height: calc(100vh - #{$panel-height});
  background-color: white;
  overflow-y: auto;
  z-index: 19;

  a {
    text-align: left;
  }

  a,
  div {
    cursor: pointer;
    font-weight: bold;
    color: #2c3e50;

    &.active-link {
      color: #42b983;
      text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

body {
  background-color: lightgray;
  overflow-y: scroll;
}

.navbar {
  border-bottom: 1px solid lightgrey;

  div.inline:hover {
    text-decoration: underline;
  }

  a,
  div {
    cursor: pointer;
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

button.icon {
  background-color: white !important;
}

.menu-button {
  @extend .flat;
  height: 40px;
  width: 40px;
}

button.submit {
  @extend .flat;
  @extend .md;
  @extend .dynamic;
  background-color: lightskyblue;
}

button.add {
  @extend .flat;
  @extend .md;
  @extend .dynamic;
  color: white !important;
  background-color: rgb(110, 120, 255) !important;
}

button.edit {
  @extend .flat;
  @extend .md;
  @extend .dynamic;
  background-color: #e45343;
}

.heading-page {
  @extend .text-left;
  color: #42b983;
  font-size: 18px;
}

.breadcrumb {
  color: #2c3e50;
  font-weight: bold;
  font-size: 18px;
}

.breadcrumb .disabled {
  color: #42b983;
}

.heading-title-1 {
  font-size: 30px;
  padding-top: 40px;
}

.heading-title-2 {
  @extend .text-left;
  color: lightskyblue;
  padding-top: 30px;
}

.heading-title-3 {
  padding-top: 20px;
}

.block {
  padding-top: 20px;
}

.required::after {
  content: " *";
  color: red;
}

.table-row div,
.table-row label {
  align-self: center;
}

.separator {
  padding-top: 10px;
  margin-top: 10px;
  border-top: solid 1px black;
}

.table-row {
  padding: 3px !important;
}

.table-header {
  padding: 3px !important;
}

img {
  align-self: center;
}

input.select2-search__field {
  align-items: center !important;
  display: flex !important;
  height: 34px !important;
}

li.select2-results__option {
  align-items: center !important;
  display: flex !important;
  height: 20px !important;
}

span.select2-selection__arrow,
span.select2-selection__rendered,
span.select2-selection {
  height: 41px !important;
  align-items: center !important;
  display: flex !important;
  padding-left: 14px;
  font-size: unset;
}

ul.select2-results__options {
  max-height: 600px !important;
}

span.select2-selection__arrow {
  padding-right: 20px;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 3em) 0.7em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  b {
    display: none;
  }
}

.mobile #content {
  padding: 12px;
}

.mobile .no-menu {
  width: calc(100% - 24px) !important;
}

.mobile div.table-row {
  grid-auto-flow: row;
  grid-auto-columns: unset !important;
  grid-auto-rows: unset !important;
}

.mobile div.table-header {
  grid-auto-flow: row;
  grid-auto-columns: unset !important;
  grid-auto-rows: unset !important;
}
</style>
