<template>
  <div class="v-stack v-start scroll-y h-stretch" ref="scroll">
    <div
      class="calendardatapicker-item"
      v-for="item in data"
      :key="item"
      @click="handleClick(item)"
      v-bind:class="[item == selectedItem ? 'calendardatapicker-selected' : '']"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    },
    selectedItem: {
      type: [String, Number, Boolean],
      default: null,
    },
  },
  watch: {
    selectedItem() {
      this.scrollToSelectedElement();
    },
  },
  methods: {
    handleClick(item) {
      this.$emit("itemselected", item);
    },
    scrollToSelectedElement() {
      const index = this.data.indexOf(this.selectedItem);
      if (index >= 0) {
        const container = this.$refs.scroll;
        const div = container.children[index];
        if (div && container) {
          container.scrollTop =
            div.offsetTop + div.clientHeight - container.clientHeight;
        }
      }
    },
  },
  mounted() {
    this.scrollToSelectedElement();
  },
};
</script>

<style lang="scss" scoped>
.calendardatapicker-item {
  user-select: none;
  cursor: pointer;

  padding-top: 6px;
  padding-bottom: 6px;

  &:hover {
    text-decoration: underline;
    background-color: lightgrey;
  }
}

.calendardatapicker-selected {
  font-weight: bold;
}
</style>
