export default [
    {
        path: "/projects",
        props: true,
        name: "Projects",
        component: () => import("../views/projects/Projects.vue")
    },
    {
        path: "/projects/add",
        name: "Add Project",
        component: () => import("../views/projects/AddProject.vue")
    },
    {
        path: "/projects/detail/:id",
        props: true,
        name: "Show Project",
        component: () => import("../views/projects/detail/ProjectDetail.vue"),
        children: [
            {
                props: true,
                path: "overview",
                component: () => import("../views/projects/detail/ProjectDetailOverview.vue")
            },
            {
                props: true,
                path: "finance",
                component: () => import("../views/projects/detail/ProjectDetailFinance.vue")
            },
            {
                props: true,
                path: "crew",
                component: () => import("../views/projects/detail/ProjectDetailCrew.vue")
            },
            {
                props: true,
                path: "tasks",
                component: () => import("../views/projects/detail/ProjectDetailTasks.vue")
            },
            {
                props: true,
                path: "logs",
                component: () => import("../views/projects/detail/ProjectDetailLogs.vue")
            },
        ]
    },
    {
        path: "/projects/edit/:id",
        props: true,
        name: "Edit Project",
        component: () => import("../views/projects/EditProject.vue")
    },
    {
        path: "/projects/folders/add/:id",
        props: true,
        name: "Add Storage",
        component: () => import("../views/projects/AddFolder.vue")
    },
    {
        path: "/projects/folders/:id",
        props: true,
        name: "Edit Storage",
        component: () => import("../views/projects/EditFolder.vue")
    },
    {
        path: "/projects/preproduction/:id",
        props: true,
        name: "Add PreProduction Crew",
        component: () => import("../views/projects/AddPreProduction.vue")
    },
    {
        path: "/projects/production/:id",
        props: true,
        name: "Add Production Day",
        component: () => import("../views/projects/AddProduction.vue")
    },
    {
        path: "/projects/postproduction/:id",
        props: true,
        name: "Add PostProduction Crew",
        component: () => import("../views/projects/AddPostProduction.vue")
    },
    {
        path: "/projects/staff/:id",
        props: true,
        name: "Edit Project Crew",
        component: () => import("../views/projects/EditStaff.vue")
    },
    {
        path: "/projects/expenses/add/:id",
        props: true,
        name: "Add Additional Expense",
        component: () => import("../views/projects/AddExpense.vue")
    },
    {
        path: "/projects/expenses/:id",
        props: true,
        name: "Edit Additional Expense",
        component: () => import("../views/projects/EditExpense.vue")
    },
    {
        path: "/projects/finance/:id",
        props: true,
        name: "Project Finance",
        component: () => import("../views/projects/ProjectFinance.vue")
    },
    {
        path: "/projects/pricelists/:id",
        props: true,
        name: "Project Budget",
        component: () => import("../views/projects/ProjectPricelists.vue")
    }
]