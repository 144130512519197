import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedStore from "vuex-persistedstate";

import userModule from "./user";
import clientModule from "./client";
import projectModule from "./project";
import workdayModule from "./workday";
import payModule from "./pay";
import pricelistModule from "./pricelist";
import driveModule from "./drive";
import xFilesModule from "./xfiles";
import logModule from "./log";
import lifeSchoolModule from "./lifeschool";
import notesModule from "./notes";
import chatModule from "./chat";
import carsModule from "./cars";
import plannerModule from "./planner";
import generalModule from "./general";
import vacationModule from "./vacation";

import EventBus from "@/eventbus.js";

Vue.use(Vuex);

Vue.api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/api"
      : "https://manager.lifereport.tv/api",
});

const store = new Vuex.Store({
  state: {
    id: null,
    token: null,
    accessLevel: null,
    name: null,
    path: [],
  },
  plugins: [createPersistedStore()],
  modules: {
    userModule,
    clientModule,
    projectModule,
    workdayModule,
    payModule,
    pricelistModule,
    driveModule,
    xFilesModule,
    logModule,
    lifeSchoolModule,
    notesModule,
    chatModule,
    carsModule,
    plannerModule,
    generalModule,
    vacationModule
  },
  mutations: {
    setUser(state, data) {
      state.token = data.token;
      state.id = data.id;
      state.accessLevel = data.accessLevel;
      state.name = data.name;
      EventBus.$emit("newToken", data.token);
    },
    addToPath(state, data) {
      const index = state.path
        .map((element) => element.path)
        .indexOf(data.path);
      if (index > -1) {
        state.path.splice(index + 1);
      } else {
        state.path.push(data);
      }

      if (
        !data.fromName &&
        data.fromPath &&
        data.fromPath.includes(data.path)
      ) {
        const fromCount = data.fromPath.split("/").length;
        const toCount = data.path.split("/").length;
        if (fromCount - 1 == toCount && data.path != "/") {
          Vue.router.go(-1);
        }
      }
    },
    removeLastFromPath(state) {
      if (state.path.length > 1) {
        state.path.pop();
      }
    },
    erasePath(state) {
      state.path = [];
    },
  },
  actions: {
    handle_response_error(_, error) {
      EventBus.$emit("message", {
        text: error.response.data.message
          ? error.response.data.message
          : error.response.data.error
            ? error.response.data.error
            : error.response.data,
      });

      if (error.response.status == 401) {
        Vue.router.push("/login");
      }
    },
  },
});

Vue.api.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = "bearer " + store.state.token || "";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

Vue.api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response == undefined) {
    error.response = {}
    error.response.data = {}
  }
  return Promise.reject(error);
});

export default store;
