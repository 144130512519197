export default [
    {
        path: "/pricelist",
        name: "Pricelist",
        component: () => import("../views/pricelist/Pricelist.vue")
    },
    {
        path: "/pricelist/item/add/:id",
        name: "Add Pricelist Item",
        component: () => import("../views/pricelist/AddPricelistItem.vue"),
        props: true,
    },
    {
        path: "/pricelist/item/import/:id",
        name: "Import Pricelist Items",
        component: () => import("../views/pricelist/PricelistImportItems.vue"),
        props: true,
    },
    {
        path: "/pricelist/item/edit/:id",
        name: "Edit Pricelist Item",
        props: true,
        component: () => import("../views/pricelist/EditPricelistItem.vue")
    },
    {
        path: "/pricelist/item/detail/:id",
        name: "Pricelist Item Detail",
        props: true,
        component: () => import("../views/pricelist/PricelistItemDetail.vue")
    },
    {
        path: "/pricelist/list/detail/:id",
        name: "Pricelist Detail",
        props: true,
        component: () => import("../views/pricelist/PricelistDetail.vue")
    },
    {
        path: "/pricelist/gear/edit/:id",
        name: "Edit Gear",
        props: true,
        component: () => import("../views/pricelist/EditGearItem.vue")
    },
    {
        path: "/pricelist/gear/add/:id",
        name: "Add Gear",
        props: true,
        component: () => import("../views/pricelist/AddGearItem.vue")
    },
    {
        path: "/pricelist/templates/",
        name: "Pricelist Templates",
        props: true,
        component: () => import("../views/pricelist/PricelistTemplates.vue")
    }
]