<template>
  <div
    class="calendar-month-grid"
    v-bind:class="[fullscreen ? 'calendar-month-grid-fullscreen' : '']"
  >
    <div
      class="calendar-month-item calendar-month-days"
      v-for="weekDay in weekDays"
      :key="weekDay._id"
    >
      {{ weekDay.name }}
    </div>
    <div
      v-for="dateObject in dates"
      :key="dateObject._id"
      class="calendar-month-item v-stack v-stretch h-stretch"
      v-bind:class="[shouldDraw(dateObject) ? '' : 'calendar-month-hidden']"
    >
      <div class="pane-top hide-y">
        <div
          :title="
            dateObject.events.length > 0 ? dateObject.events.length : null
          "
          @click="handleClick(dateObject)"
          v-bind:class="[
            dateObject.dark ? 'calendar-month-dark' : '',
            dateObject.today && highlightToday ? 'calendar-month-today' : '',
            dateObject.selected && highlightSelected
              ? 'calendar-month-selected'
              : '',
          ]"
          class="calendar-month-title calendar-month-link"
        >
          {{ dateObject.date.date() }}
          <label v-if="fullscreen && dateObject.events.length > 0">
            ({{ dateObject.events.length }})
          </label>
        </div>
        <div
          class="v-stack v-start h-stretch calendar-month-events"
          style="overflow-y: auto; overflow-x: hidden"
          v-if="fullscreen"
        >
          <CalendarEventLine
            v-for="event in dateObject.events"
            :key="event._id"
            :event="event"
            class="calendar-month-event"
          ></CalendarEventLine>
        </div>
        <div
          v-if="!fullscreen"
          class="h-stack gap-1"
          style="font-size: 6px; height: 9px"
        >
          <i
            v-if="eventHasBirthday(dateObject.events)"
            class="fas fa-circle fa-xs"
            style="color: lightskyblue"
          ></i>
          <i
            v-if="eventHasOther(dateObject.events)"
            class="fas fa-circle fa-xs"
          ></i>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import moment from "moment";
import { v4 as uuid } from "uuid";
import utils from "@/utils.js";
import CalendarEventLine from "@/components/calendar/CalendarEventLine.vue";

export default {
  data() {
    return {
      calendarObject: utils.calendarObject,
    };
  },
  components: {
    CalendarEventLine,
  },
  props: {
    events: {
      type: Object,
      default: () => {
        return {};
      },
    },
    date: {
      type: Object,
      default: () => moment(),
    },
    month: {
      type: Number,
      default: moment().month(),
    },
    year: {
      type: Number,
      default: moment().year(),
    },
    highlightToday: {
      type: Boolean,
      default: true,
    },
    highlightSelected: {
      type: Boolean,
      default: true,
    },
    showOverflow: {
      type: Boolean,
      default: true,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "date",
    event: "datechange",
  },
  methods: {
    handleClick(dateObject) {
      if (this.shouldDraw(dateObject)) {
        this.$emit("datechange", dateObject.date);
        this.$emit("dateselected");
      }
    },
    shouldDraw(dateObject) {
      return (
        this.showOverflow ||
        (dateObject.date.month() == this.month &&
          dateObject.date.year() == this.year)
      );
    },
    eventHasBirthday(events) {
      for (const event of events) {
        if (event.eventType == "birthday") {
          return true;
        }
      }
      return false;
    },
    eventHasOther(events) {
      for (const event of events) {
        if (event.eventType != "birthday") {
          return true;
        }
      }
      return false;
    },
  },
  computed: {
    weekDays() {
      const days = [];
      for (let i = 0; i < 7; i++) {
        days.push({
          _id: uuid(),
          name: moment().weekday(i).format("ddd"),
        });
      }
      return days;
    },
    dates() {
      const offset =
        moment().year(this.year).month(this.month).date(1).weekday() - 1;
      const daysInMonth = moment()
        .year(this.year)
        .month(this.month)
        .daysInMonth();

      const dates = [];
      for (let i = 0; i < 42; i++) {
        const date = i - offset;
        const fullDate = moment().year(this.year).month(this.month).date(date);
        dates.push({
          _id: uuid(),
          date: fullDate,
          dark: date < 1 || date > daysInMonth ? true : false,
          today: fullDate.isSame(moment(), "date"),
          selected: fullDate.isSame(this.date, "date"),
          events: this.calendarObject.getDayEvents(fullDate, this.events),
        });
      }
      return dates;
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-month-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-month-item {
  padding: 3px;
  // max-height: 50px;
}

.calendar-month-grid-fullscreen {
  grid-template-rows: 25px repeat(auto-fit, minmax(10px, 1fr));
  background-color: lightgray;
  gap: 1px;
  border: 1px solid lightgray;
}

.calendar-month-grid-fullscreen .calendar-month-item {
  background-color: white;
  padding: 0px;
}

.calendar-month-event {
  text-align: left;
  color: white;
  margin: 0px -3px 3px -3px;
  padding: 6px;
  font-size: 14px;
  border-bottom: 1px solid white;
}

.calendar-month-grid-fullscreen .calendar-month-event {
  margin: 0px;
}

.calendar-month-dark {
  color: grey;
}

.calendar-month-days {
  font-weight: bold;
  border: unset !important;
}

.calendar-month-title {
  justify-self: center;
  align-self: center;
  height: 30px;
  width: 30px;
  line-height: 31px;
  position: relative;
}

.calendar-month-title label {
  position: absolute;
  top: -1px;
  right: 6px;
  font-weight: normal;
}

.calendar-month-grid-fullscreen .calendar-month-title {
  width: 100%;
}

.calendar-month-grid-fullscreen .calendar-month-events {
  padding: 0px;
}

.calendar-month-events {
  padding: 3px;

  &::-webkit-scrollbar {
    width: 6px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: lightgray; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: grey; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    // border: 3px solid orange; /* creates padding around scroll thumb */
  }
}

.calendar-month-hidden {
  opacity: 0;

  & div:hover {
    cursor: unset !important;
  }
}

.calendar-month-today {
  font-weight: bold;
  color: rgb(110, 120, 255);
}

.calendar-month-selected {
  color: white;
  background-color: lightskyblue;
  border-radius: 50%;
}

.calendar-month-today.calendar-month-selected {
  color: white;
  border-radius: 50%;
  background-color: rgb(110, 120, 255);
}

.calendar-month-link {
  user-select: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
