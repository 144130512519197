const detailChildren = [
    {
        props: true,
        path: "settings",
        component: () => import("../views/system/SystemSettings.vue"),
    },
    {
        props: true,
        path: "groups",
        component: () => import("../views/system/SystemGroups.vue"),
    },
    {
        props: true,
        path: "backup",
        component: () => import("../views/system/SystemBackup.vue"),
    },
];

export default [
    {
        path: "/system",
        name: "System",
        component: () => import("../views/system/System.vue"),
        children: detailChildren,
    },
];
