import Vue from 'vue'

export default {
    actions: {
        getWorkhoursForProjects({ dispatch }, projects) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/workhours/projects", projects).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getScheduleItems({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/scheduleitem").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addScheduleItem({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/scheduleitem", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        editScheduleItem({ dispatch }, item) {
            return new Promise((resolve, reject) => {
                Vue.api.patch("/scheduleitem/" + item._id, item).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        removeScheduleItem({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.delete("/scheduleitem/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
    }
}