import Vue from 'vue'

export default {
    actions: {
        getWorkdays({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/workdays").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getUnsignedWorkdays({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/workdays/unsigned").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getWorkday({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/workdays/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        signWorkday({ dispatch }, workday) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/workdays/sign", {
                    workday: workday
                }).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        cancelWorkday({ dispatch }, workday) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/workdays/cancel", {
                    workday: workday
                }).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addWorkday({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/workdays/add", data).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        }
    }
}