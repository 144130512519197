import Vue from "vue";

export default {
  actions: {
    addLifeSchoolCategory({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .post("/lifeschool/category", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getLifeSchoolCategories({ dispatch }) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/lifeschool/category")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    addLifeSchoolItem({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .post("/lifeschool/item", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    addLifeSchoolComment({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .post("/lifeschool/comment", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    editLifeSchoolItem({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .patch("/lifeschool/item/" + data._id, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    removeLifeSchoolItem({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        Vue.api
          .delete("/lifeschool/item/" + id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getLifeSchoolItem({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/lifeschool/item/" + id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getLifeSchoolItems({ dispatch }) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/lifeschool/item")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
  },
};
