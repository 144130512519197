export default [
  {
    path: "/lifecars",
    name: "lifeCars",
    component: () => import("../views/cars/LifeCars.vue"),
  },
  {
    path: "/lifecars/car/add",
    name: "New Car",
    component: () => import("../views/cars/AddEditCar.vue"),
  },
  {
    path: "/lifecars/car/trip/add/:id",
    name: "Add Trip",
    component: () => import("../views/cars/AddTrip.vue"),
    props: true,
  },
  {
    path: "/lifecars/car/trip/edit/:id",
    name: "Edit Trip",
    component: () => import("../views/cars/EditTrip.vue"),
    props: true,
  },
  {
    path: "/lifecars/car/edit/:id",
    name: "Edit Car",
    component: () => import("../views/cars/AddEditCar.vue"),
    props: true
  },
  {
    path: "/lifecars/car/detail/:id",
    name: "Car Detail",
    props: true,
    component: () => import("../views/cars/CarDetail.vue"),
  },
];
