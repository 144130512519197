<template>
  <div
    ref="mastercontainer"
    class="master-container"
    v-show="selectedEvent && selectedElement"
  >
    <div ref="container" class="calendarpopup-container">
      <div class="card v-start" v-if="selectedEvent && selectedElement">
        <div
          class="header h-stretch"
          :style="
            'background-color: ' + eventTypes[selectedEvent.eventType].color
          "
        >
          <div class="pane-right">
            <div class="h-stack h-start gap-3">
              <div>
                <i :class="eventTypes[selectedEvent.eventType].icon"></i>
              </div>
              <div class="text-left">{{ selectedEvent.title }}</div>
            </div>
            <div class="h-stack gap-3">
              <div
                class="link"
                v-if="!edit && selectedEvent.url"
                @click="$router.push(selectedEvent.url)"
              >
                <i class="fas fa-external-link-alt"></i>
              </div>
              <div
                class="link"
                @click="editMode()"
                v-if="!edit && selectedEvent.editable"
              >
                <i class="fas fa-edit"></i>
              </div>
              <div class="link" v-if="edit">
                <i class="fas fa-check"></i>
              </div>
              <div class="link" @click="edit ? cancelEdit() : close()">
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="content h-stretch v-start">
          <div class="v-stack h-stretch gap-3">
            <div class="h-stack h-space-between">
              <div class="h-stack gap-1 h-start">
                <label>{{
                  formatDate(selectedEvent.startDate, "DD.MM.YYYY")
                }}</label>
                <label v-if="selectedEvent.eventType != 'birthday'">{{
                  formatDate(selectedEvent.startDate, "HH:mm")
                }}</label>
                <template v-if="selectedEvent.endDate">
                  <label>-</label>
                  <label
                    v-if="
                      isNotSameDate(
                        selectedEvent.startDate,
                        selectedEvent.endDate
                      )
                    "
                    >{{
                      formatDate(selectedEvent.endDate, "DD.MM.YYYY")
                    }}</label
                  >
                  <label>{{
                    formatDate(selectedEvent.endDate, "HH:mm")
                  }}</label>
                </template>
              </div>
              <label
                :style="'color: ' + eventTypes[selectedEvent.eventType].color"
                >{{ selectedEvent.eventType }}</label
              >
            </div>
            <div class="v-stack v-start h-stretch scroll-y gap-3">
              <template v-if="selectedEvent.description != null">
                <div
                  class="v-stack h-stretch v-start gap-2"
                  v-for="description in selectedEvent.description"
                  :key="description.title"
                >
                  <div class="h-stack h-space-between">
                    <label class="text-left description-title"
                      >{{ description.title }}:</label
                    >
                    <button
                      v-if="description.link"
                      class="goto"
                      @click="openLink(description.link)"
                    >
                      {{ description.linkTitle }}
                    </button>
                    <div v-if="!description.link"></div>
                  </div>
                  <div class="multiline" v-html="description.text"></div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="footer h-start">
          <CalendarUserBadge
            class="sm"
            v-for="user in selectedEvent.crew"
            :key="user._id"
            :user="user"
          >
          </CalendarUserBadge>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/eventbus.js";
import utils from "@/utils.js";
import CalendarUserBadge from "@/components/calendar/CalendarUserBadge.vue";

export default {
  data() {
    return {
      edit: false,
      selectedEvent: null,
      selectedElement: null,
      lastClick: null,
      eventTypes: utils.calendarObject.eventTypes,
    };
  },
  components: {
    CalendarUserBadge,
  },
  props: {
    event: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openLink(link) {
      if (link.startsWith("http")) {
        window.open(link, "_blank");
      } else {
        this.$router.push(link);
      }
    },
    editMode() {
      this.edit = true;
      this.$refs.mastercontainer.classList.add("edit");
    },
    formatDate(date, format) {
      return date.format(format);
    },
    isNotSameDate(startDate, endDate) {
      return !startDate.isSame(endDate, "day");
    },
    resize() {
      if (this.selectedElement == null || this.$refs.container == null) {
        return;
      }
      const boundingRect = this.selectedElement.getBoundingClientRect();

      if (boundingRect.x + boundingRect.width / 2 > window.innerWidth / 2) {
        this.$refs.container.style.left = `${boundingRect.x - 400}px`;
      } else {
        this.$refs.container.style.left = `${
          boundingRect.x + boundingRect.width
        }px`;
      }

      this.$refs.container.style.top = `${Math.min(
        Math.max(boundingRect.y, 165),
        window.innerHeight - 400
      )}px`;
    },
    handleClick() {
      if (Date.now() - this.lastClick > 10) {
        if (!this.$refs.container.matches(":hover") && !this.edit) {
          this.close();
        }
      }
    },
    close() {
      this.selectedEvent = null;
      this.selectedElement = null;
      this.cancelEdit();
    },
    cancelEdit() {
      this.edit = false;
      this.$refs.mastercontainer.classList.remove("edit");
    },
  },
  mounted() {
    EventBus.$on("calendareventselected", (data) => {
      this.lastClick = Date.now();
      this.selectedEvent = data.event;
      this.selectedElement = data.element;
      this.resize();
    });
  },
  created() {
    window.addEventListener("resize", this.resize);
    window.addEventListener("scroll", this.resize);
    window.addEventListener("click", this.handleClick);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
    window.removeEventListener("scroll", this.resize);
    window.removeEventListener("click", this.handleClick);
  },
};
</script>

<style lang="scss" scoped>
.master-container {
  position: fixed;
  z-index: 29;
}

.master-container.edit {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.calendarpopup-container {
  position: fixed;
  z-index: 30;

  width: 400px;
  height: 400px;
}

.card {
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  margin: 6px;
  padding: 0px;
  box-shadow: 0px 0px 20px 6px rgb(97, 97, 97);

  display: grid;
  grid-template-rows: auto 1fr auto;
}

.master-container.edit .card {
  box-shadow: none;
}

.card .header {
  padding: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: white;
}

.card .content {
  overflow: hidden;
  padding: 10px;
}

.card .footer {
  padding: 3px;
}

.content > .v-stack {
  overflow-y: auto;
}

.link {
  user-select: none;
  cursor: pointer;
  display: grid;

  width: 20px;
  height: 20px;
  padding: 3px;

  i {
    align-self: center;
    justify-self: center;
  }

  &:hover {
    background-color: rgb(73, 73, 73);
    border-radius: 50%;
  }
}

.multiline {
  white-space: pre-wrap;
  text-align: left;
  overflow: visible;
}

.no-scroll {
  overflow-y: visible;
}

button.goto {
  background: none;
  box-shadow: none;
  border: 1px solid black;
  width: unset;
  color: black;
  height: unset;
  font-size: 15px;
  line-height: 15px;
  padding: 6px;
  letter-spacing: 1px;
  // font-weight: unset;
}

.description-title {
  align-self: center;
}
</style>
