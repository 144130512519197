import Vue from "vue";

export default {
    actions: {
        getNotes({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api
                    .get("/notes")
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch("handle_response_error", error);
                        reject(error.response);
                    });
            });
        },
        addNote({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api
                    .post("/notes", data)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch("handle_response_error", error);
                        reject(error.response);
                    });
            });
        },
        editNote({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api
                    .patch("/notes/" + data._id, data)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch("handle_response_error", error);
                        reject(error.response);
                    });
            });
        },
        removeNote({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api
                    .delete("/notes/" + id)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch("handle_response_error", error);
                        reject(error.response);
                    });
            });
        },
        moveNoteUp({ dispatch }, index) {
            return new Promise((resolve, reject) => {
                Vue.api
                    .post("/notes/up/" + index)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch("handle_response_error", error);
                        reject(error.response);
                    });
            });
        },
        moveNoteDown({ dispatch }, index) {
            return new Promise((resolve, reject) => {
                Vue.api
                    .post("/notes/down/" + index)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch("handle_response_error", error);
                        reject(error.response);
                    });
            });
        },
    },
};
