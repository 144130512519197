import Vue from 'vue'

export default {
    actions: {
        getProjects({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/projects").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getAssignedProjects({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/projects/assigned").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getPlannedProjects({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/projects/planned/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getDeadlineProjects({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/projects/deadlines").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getProject({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/projects/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addProject({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/projects/add", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        selectProjectPricelist({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/projects/pricelist", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        editProject({ dispatch }, project) {
            return new Promise((resolve, reject) => {
                Vue.api.patch("/projects/" + project._id, project).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        archiveProject({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/projects/archive/" + id).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addComment({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/projects/comments/add", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addFolder({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/projects/folders/add", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getFolder({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/projects/folders/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        editFolder({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.patch("/projects/folders/" + data._id, data).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        removeFolder({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.delete("/projects/folders/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addExpense({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/projects/expenses/add", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getExpense({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/projects/expenses/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getExpenses({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/expenses/").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        updateExpense({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.patch("/projects/expenses/" + data._id, data).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        removeExpense({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.delete("/projects/expenses/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addStaffPreProduction({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/projects/staff/preproduction/add", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addStaffPostProduction({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/projects/staff/postproduction/add", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addStaffProduction({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/projects/staff/production/add", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getStaff({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/projects/staff/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        editStaff({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.patch("/projects/staff/" + data._id, data).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        removeStaff({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.delete("/projects/staff/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addRecordingDay({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/recordingdays/add", data).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getRecordingDay({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/recordingdays/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getRecordingDays({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/recordingdays").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getRecordingDaysByUser({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/recordingdays/user").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        editRecordingDay({ dispatch }, day) {
            return new Promise((resolve, reject) => {
                Vue.api.patch("/recordingdays/" + day._id, day).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        removeRecordingDay({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.delete("/recordingdays/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
    }
}