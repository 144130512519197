<template>
  <div class="h-stack h-stretch root">
    <div
      class="item"
      v-for="item in items"
      :key="item.value"
      v-bind:class="[item.value == value ? 'selected' : '']"
      @click="$emit('valuechanged', item.value)"
    >
      {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
  },
  model: {
    prop: "value",
    event: "valuechanged",
  },
};
</script>

<style lang="scss" scoped>
.root {
  :first-child {
    border-radius: 9px 0px 0px 9px;
  }
  :last-child {
    border-radius: 0px 9px 9px 0px;
    border: 1px rgb(110, 120, 255) solid;
  }
}

.item {
  padding: 6px;
  user-select: none;
  cursor: pointer;
  border: 1px rgb(110, 120, 255) solid;
  border-right: none;

  &:hover {
    text-decoration: underline;
  }

  &.selected {
    background-color: rgb(110, 120, 255);
    color: white;
  }
}
</style>
