<template>
  <div class="h-stack h-stretch">
    <div class="h-stack calendarviewday-title h-stretch">
      <div
        class="calendarviewweek-title-leftarrow calendarviewweek-link"
        @click="addWeek(-1)"
      >
        <i class="fas fa-angle-left"></i>
      </div>
      <div
        class="calendarviewweek-title-rightarrow calendarviewweek-link"
        @click="addWeek(1)"
      >
        <i class="fas fa-angle-right"></i>
      </div>
      <div class="v-stack gap-3 h-stretch" v-for="day in days" :key="day._id">
        <div
          class="calendarviewday-title-name calendarviewweek-link"
          @click="handleClick(day.date)"
        >
          {{ day.date.format("dd") }}
        </div>
        <div
          class="calendarviewday-title-date calendarviewweek-link"
          @click="handleClick(day.date)"
        >
          {{ day.date.format("DD") }}
        </div>
      </div>
    </div>
    <div class="h-stack h-stretch calendarviewweek-container">
      <CalendarDay
        :date="day.date"
        :showDateColumn="day.index == 0 ? true : false"
        v-for="day in days"
        :key="day._id"
        :events="events"
      ></CalendarDay>
    </div>
  </div>
</template>

<script>
import CalendarDay from "@/components/calendar/CalendarDay.vue";
import moment from "moment";
import { v4 as uuid } from "uuid";

export default {
  components: {
    CalendarDay,
  },
  props: {
    date: {
      type: Object,
      default: () => moment(),
    },
    events: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  model: {
    prop: "date",
    event: "datechange",
  },
  computed: {
    days() {
      const days = [];
      for (let i = 0; i < 7; i++) {
        days.push({
          _id: uuid(),
          index: i,
          date: this.getDay(i),
        });
      }
      return days;
    },
  },
  methods: {
    getDay(day) {
      return moment(this.date).weekday(day);
    },
    handleClick(date) {
      this.$emit("datechange", date);
      this.$emit("dateselected");
    },
    addWeek(amount) {
      this.$emit("datechange", moment(this.date.add(7 * amount, "days")));
    },
  },
};
</script>

<style lang="scss" scoped>
.calendarviewday-title {
  position: fixed;
  width: calc(100% - 520px - 40px - 40px);
  padding-left: 40px;
  margin-top: -60px;
  padding-bottom: 10px;
  background-color: white;
  border-bottom: 1px lightgray solid;
  z-index: 4;
}

.calendarviewweek-title-leftarrow {
  position: absolute;
  top: 60px;
  left: 0px;
}

.calendarviewweek-title-rightarrow {
  position: absolute;
  top: 60px;
  right: 0px;
}

.calendarviewday-title-name {
  padding-top: 40px;
  color: grey;
  font-size: 18px;
}
.calendarviewday-title-date {
  color: grey;
  font-weight: bold;
  font-size: 26px;
}

.calendarviewweek-container {
  margin-top: 55px;
}

.calendarviewweek-link {
  user-select: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

html:not([data-scroll="0"]) .calendarviewday-title {
  border-bottom: 2px lightgray solid;
}
</style>
