export default [
    {
        path: "/xfiles",
        name: "X-Files",
        component: () => import("../views/xfiles/XFiles.vue")
    },
    {
        path: "/xfiles/add",
        name: "X-Files Item Add",
        component: () => import("../views/xfiles/XFilesItemEdit.vue")
    },
    {
        path: "/xfiles/edit/:id",
        name: "X-Files Item Edit",
        props: true,
        component: () => import("../views/xfiles/XFilesItemEdit.vue")
    },
]