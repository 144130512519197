import Vue from "vue";

export default {
    actions: {
        getSettings({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api
                    .get("/settings")
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch("handle_response_error", error);
                        reject(error.response);
                    });
            });
        },
        updateSettings({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api
                    .patch("/settings/", data)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch("handle_response_error", error);
                        reject(error.response);
                    });
            });
        },
    }
}