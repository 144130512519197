var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-stack h-stretch gap-3"},[_c('Table',{attrs:{"items":_vm.data,"defaultColumn":0,"css":'100px 180px 1fr 40px 70px 80px 80px 100px',"columns":[
      {
        name: 'Date',
        sort: 'date',
        path: ['day'],
        formatType: 'date',
        format: 'DD.MM.YYYY',
      },
      {
        name: 'Name',
        sort: 'alphabet',
        path: ['user', 'fullName'],
      },
      {
        name: 'Time / Description',
        sort: 'alphabet',
        formatMap: function (workday) { return workday.oneTimePay
            ? workday.description
            : ((_vm.formatDate(workday.start, 'HH:mm')) + " - " + (_vm.formatDate(
              workday.end,
              'HH:mm'
            ))); },
      },
      {
        name: 'Hours',
        sort: 'alphabet',
        formatMap: function (workday) { return workday.oneTimePay ? '' : ((_vm.sumWorkhours(workday.workhours)) + " h"); },
      },
      {
        name: 'Money',
        sort: 'alphabet',
        formatMap: function (workday) { return ("" + (workday.oneTimePay
            ? Math.round(workday.amount)
            : _vm.calculateMoney(workday.workhours))); },
        formatType: 'currency',
        format: 'czk',
      },
      {
        name: 'Created By',
        sort: 'alphabet',
        path: ['createdBy', 'fullName'],
      },
      {
        name: 'Signed By',
        sort: 'alphabet',
        path: ['reviewedBy', 'fullName'],
      },
      {
        name: 'Signed',
        alignTitle: 'center',
      } ]},on:{"rowSelected":function($event){return _vm.onclick($event)}},scopedSlots:_vm._u([{key:"Signed",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"h-stack h-center"},[(item.state == 'signed')?_c('div',[_c('i',{staticClass:"fas fa-check fa-lg text-success"})]):_vm._e(),(item.state == 'canceled')?_c('div',[_c('i',{staticClass:"fas fa-times fa-2x text-error"})]):_vm._e(),(_vm.allowSign && item.state == 'created')?_c('div',{staticClass:"h-stack gap-5"},[_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.cancel(item._id, item.oneTimePay)}}},[_c('i',{staticClass:"fas fa-times fa-2x text-error"})]),_c('div',{staticClass:"ma",on:{"click":function($event){$event.stopPropagation();return _vm.sign(item._id, item.oneTimePay)}}},[_c('i',{staticClass:"fas fa-check fa-lg text-success"})])]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }