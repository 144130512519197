import Vue from 'vue'

export default {
    actions: {
        getConversations({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/conversations").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        editConversation({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.patch("/conversation/update/" + data.id, data).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addConversation({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/conversations").then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        sendConversationMessage({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/conversations/message", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addConversationUser({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/conversations/user", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        removeConversationUser({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.put("/conversations/user", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
    }
}