export default [
    {
        path: "/storage",
        name: "Drives",
        component: () => import("../views/storage/Storage.vue")
    },
    {
        path: "/storage/add",
        props: true,
        name: "Add Drive",
        component: () => import("../views/storage/EditStorage.vue")
    },
    {
        path: "/storage/edit/:id",
        props: true,
        name: "Edit Drive",
        component: () => import("../views/storage/EditStorage.vue")
    },
    {
        path: "/storage/detail/:id",
        props: true,
        name: "View Drive",
        component: () => import("../views/storage/StorageDetail.vue")
    },
]