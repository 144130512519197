export default [
  {
    path: "/lifeschool",
    name: "lifeSchool",
    component: () => import("../views/lifeschool/LifeSchool.vue"),
  },
  {
    path: "/lifeschool/item/new",
    name: "New lifeSchool Item",
    component: () => import("../views/lifeschool/LifeSchoolEditItem.vue"),
  },
  {
    path: "/lifeschool/item/edit/:id",
    name: "Edit lifeSchool Item",
    component: () => import("../views/lifeschool/LifeSchoolEditItem.vue"),
    props: true,
  },
  {
    path: "/lifeschool/item/detail/:id",
    name: "lifeSchool Item",
    component: () => import("../views/lifeschool/LifeSchoolItemDetail.vue"),
    props: true,
  },
];
