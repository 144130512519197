import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import search from "@/views/Search.vue";
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import "vue-simple-calendar/static/css/default.css";
import Select2 from "v-select2-component";
import VueMobileDetection from 'vue-mobile-detection'

Vue.use(VueMobileDetection)

Vue.component("Select2", Select2);
Vue.component("calendarview", CalendarView);
Vue.component("calendarviewheader", CalendarViewHeader);
Vue.component("Search", search);
Vue.component("datetime", Datetime);
Vue.config.productionTip = false;
Vue.router = Vue.observable(router);
Vue.store = Vue.observable(store);

Vue.mouseX = 0;
Vue.mouseY = 0;

document.addEventListener("mousemove", (event) => {
  Vue.mouseX = event.clientX;
  Vue.mouseY = event.clientY;
});

Vue.directive("focus", {
  inserted: function (el) {
    el.focus();
    if (el.select) {
      el.select();
    }
  },
});

Vue.links = [
  {
    path: "/",
    name: "Dashboard",
    accessLevel: 0,
  },
  {
    path: "/calendar/calendar",
    name: "Calendar",
    accessLevel: 5,
  },
  {
    path: "/calendar/planner",
    name: "Planner",
    accessLevel: 5,
  },
  {
    path: "/projects",
    name: "Projects",
    accessLevel: 0,
  },
  {
    path: "/clients",
    name: "Clients",
    accessLevel: 5,
  },
  {
    path: "/pricelist",
    name: "Pricelist",
    accessLevel: 9,
  },
  {
    path: "/storage",
    name: "Drives",
    accessLevel: 5,
  },
  {
    path: "/notes",
    name: "lifeNotes",
    accessLevel: 5,
  },
  {
    path: "/lifeChat",
    name: "lifeChat",
    accessLevel: 5,
  },
  {
    path: "/lifeschool",
    name: "lifeSchool",
    accessLevel: 5,
  },
  {
    path: "/rental",
    name: "Rental",
    accessLevel: 5,
  },
  {
    path: "/lifecars",
    name: "lifeCars",
    accessLevel: 5,
  },
  {
    path: "/xfiles",
    name: "X-Files",
    accessLevel: 5,
  },
  {
    path: "/crew",
    name: "lifeCrew",
    accessLevel: 5,
  },
  {
    path: "/freelancers",
    name: "Freelancers",
    accessLevel: 5,
  },
  {
    path: "/customers",
    name: "Customers",
    accessLevel: 5,
  },
  {
    path: "/lifefinance",
    name: "lifeFinance",
    accessLevel: 10,
  },
  {
    path: "/account",
    name: "Account",
    accessLevel: 0,
  },
  {
    path: "/system",
    name: "System",
    accessLevel: 10,
  },
];

router.afterEach(() => {
  Vue.nextTick(() => {
    const content = document.getElementById("content");
    if (
      document.querySelectorAll(
        '[class^="panel-content"],[class*="panel-content"]'
      ).length > 0
    ) {
      if (content.classList.contains("crop")) {
        content.classList.remove("crop");
      }
    } else {
      if (!content.classList.contains("crop")) {
        content.classList.add("crop");
      }
    }
  });
});

router.beforeEach((to, from, next) => {
  if (to.path == "/login") {
    store.commit("erasePath");
    next();
    return;
  }

  if (store.state.path.length == 0) {
    store.commit("addToPath", {
      name: from.name,
      path: from.path,
    });
  }

  for (const link of Vue.links) {
    if (link.path == to.path) {
      store.commit("erasePath");
    }
  }

  if (to.name) {
    store.commit("addToPath", {
      name: to.name,
      path: to.path,
      fromPath: from.path,
      fromName: from.name,
    });
  } else {
    if (from.name) {
      if (!to.path.includes(from.path)) {
        store.commit("removeLastFromPath");
      }
    }
  }

  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
