<template>
  <div class="v-stack h-stretch v-start">
    <div class="v-stack h-stretch calendarviewday-container">
      <CalendarEventLine
        class="calendarday-event v-start"
        v-for="event in filteredEvents"
        :key="event._id"
        :event="event"
        :id="event._id + date.toISOString()"
      ></CalendarEventLine>
      <div
        class="
          pane-left
          calendarviewday-row
          v-stretch
          calendarviewday-placeholder
        "
        v-for="hourObject in hours"
        :key="hourObject._id"
      >
        <label v-if="showDateColumn" class="calendarviewday-hour">{{
          hourObject.hour
        }}</label>
        <div v-if="!showDateColumn"></div>
        <div class="h-stack calendarviewday-placeholder"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import moment from "moment";
import utils from "@/utils.js";
import CalendarEventLine from "@/components/calendar/CalendarEventLine.vue";

export default {
  data() {
    return {
      calendarObject: utils.calendarObject,
      filteredEvents: [],
    };
  },
  components: {
    CalendarEventLine,
  },
  props: {
    date: {
      type: Object,
      default: () => moment(),
    },
    showDateColumn: {
      type: Boolean,
      default: true,
    },
    events: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    date() {
      this.updateEvents();
      this.adjustEvents();
    },
    events() {
      this.updateEvents();
      this.adjustEvents();
    },
    showDateColumn() {
      this.updateEvents();
      this.adjustEvents();
    },
  },
  methods: {
    updateEvents() {
      this.filteredEvents = this.calendarObject.getDayEvents(
        this.date,
        this.events
      );
    },
    checkOverlap(el1, el2) {
      const domRect1 = el1.getBoundingClientRect();
      const domRect2 = el2.getBoundingClientRect();

      return (
        domRect2.left < domRect1.right &&
        domRect2.right > domRect1.left &&
        domRect2.top < domRect1.bottom &&
        domRect2.bottom > domRect1.top
      );
    },
    adjustEvents() {
      this.$nextTick(() => {
        const eventsChache = [];

        for (const event of this.filteredEvents) {
          const element = document.getElementById(
            event._id + this.date.toISOString()
          );

          let overlaps = 0;
          let overlapsTop = 0;
          for (const cachedEvent of eventsChache) {
            if (
              event.eventType == "birthday" ||
              event.eventType == "deadline" ||
              (event.endDate != null &&
                !event.startDate.isSame(event.endDate, "day"))
            ) {
              if (
                cachedEvent.eventType == "birthday" ||
                cachedEvent.eventType == "deadline" ||
                (cachedEvent.endDate != null &&
                  !cachedEvent.startDate.isSame(cachedEvent.endDate, "day"))
              ) {
                if (
                  event.startDate.isBetween(
                    cachedEvent.startDate,
                    cachedEvent.endDate,
                    "day",
                    "[]"
                  ) ||
                  // event.endDate.isBetween(
                  //   cachedEvent.startDate,
                  //   cachedEvent.endDate,
                  //   "day",
                  //   "[]"
                  // ) ||
                  event.eventType == "birthday" ||
                  event.eventType == "deadline" ||
                  cachedEvent.eventType == "birthday" ||
                  cachedEvent.eventType == "deadline"
                ) {
                  overlapsTop++;
                }
              }
            } else {
              if (
                cachedEvent.startDate &&
                cachedEvent.endDate &&
                cachedEvent.eventType != "birthday" &&
                cachedEvent.eventType != "deadline" &&
                cachedEvent.startDate.isSame(cachedEvent.endDate, "day")
              ) {
                if (
                  event.startDate.isBetween(
                    cachedEvent.startDate,
                    cachedEvent.endDate,
                    undefined,
                    "[]"
                  )
                ) {
                  overlaps++;
                }
              }
            }
          }

          eventsChache.push(event);
          const overlapOffset = 15;

          element.style.left = `${
            (this.showDateColumn ? 40 : 0) + overlaps * overlapOffset
          }px`;

          element.style.width = this.showDateColumn
            ? `calc(100% - 1px - 6px - 40px - ${overlaps * overlapOffset}px)`
            : `calc(100% - 1px - 6px - ${overlaps * overlapOffset}px)`;
          if (
            (event.endDate == null ||
              event.startDate.isSame(event.endDate, "day")) &&
            event.eventType != "birthday" &&
            event.eventType != "deadline"
          ) {
            element.style.top = `${(60 + 1) * event.startDate.hour()}px`;

            if (event.endDate != null) {
              element.style.height = `${
                (60 + 1) * (event.endDate.hour() - event.startDate.hour()) -
                6 -
                1
              }px`;
            } else {
              element.style.height = "54px";
            }
          } else {
            element.style.height = "17px";
            element.style.top = `${overlapsTop * 24}px`;
            element.style.zIndex = 3;
            element.classList.add("oneline");
          }
        }

        const elements = document.getElementsByClassName("calendareventline");
        for (const element of elements) {
          element.onmouseover = () => {
            const elements =
              document.getElementsByClassName("calendareventline");
            for (const otherElement of elements) {
              if (
                otherElement != element &&
                this.checkOverlap(element, otherElement)
              ) {
                otherElement.style.opacity = 0.15;
              }
            }
            element.style.opacity = 1;
          };
          element.onmouseout = () => {
            const elements =
              document.getElementsByClassName("calendareventline");
            for (const otherElement of elements) {
              otherElement.style.opacity = 1;
            }
          };
        }
      });
    },
  },
  mounted() {
    this.updateEvents();
    this.adjustEvents();
  },
  computed: {
    hours() {
      const hours = [];

      for (let i = 0; i < 24; i++) {
        hours.push({
          hour: i,
          _id: uuid(),
        });
      }

      return hours;
    },
  },
};
</script>

<style lang="scss" scoped>
.calendarviewday-row {
  z-index: 2;
  border-bottom: 1px solid lightgray;
  height: 60px;

  .h-stack {
    border-left: 1px solid lightgray;
  }

  .v-stack {
    border-left: 1px solid lightgray;
  }
}

.calendarviewday-placeholder {
  pointer-events: none;
}

.calendarviewday-hour {
  width: 40px;
}

.calendarday-event {
  position: absolute;
  color: white;
  padding: 3px;
  font-size: 14px;
  border-left: 1px solid lightgray;
}

.calendarday-event.oneline {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid white;
}

.calendarviewday-container {
  position: relative;
}
</style>
