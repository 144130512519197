<template>
  <div class="v-stack h-stretch gap-3">
    <Table
      :items="customLoans"
      :css="'80px 80px 80px 80px 100px 1fr 100px'"
      :columns="[
        {
          name: 'Start Date',
          sort: 'date',
          formatType: 'date',
          format: 'DD.MM.YYYY',
          path: ['startDate'],
        },
        {
          name: 'Amount',
          sort: 'numeric',
          path: ['amount'],
          format: 'czk',
          formatType: 'currency',
        },
        {
          name: 'Monthly',
          sort: 'numeric',
          path: ['monthlyRate'],
          format: 'czk',
          formatType: 'currency',
        },
        {
          name: 'Length',
          sort: 'numeric',
          path: ['predictedEndDate', 'duration'],
          format: ' months',
        },
        {
          name: 'Last Payment',
          sort: 'date',
          formatType: 'date',
          format: 'DD.MM.YYYY',
          path: ['predictedEndDate', 'endDate'],
        },
        {
          name: 'Description',
          sort: 'alphabet',
          path: ['description'],
        },
        {
          name: 'Signed',
          alignTitle: 'center',
        },
      ]"
    >
      <template v-slot:Signed="{ item }">
        <div class="h-stack h-center">
          <div v-if="item.state == 'signed'">
            <i class="fas fa-check fa-lg text-success"></i>
          </div>
          <div v-if="item.state == 'canceled'">
            <i class="fas fa-times fa-2x text-error"></i>
          </div>
          <div class="h-stack gap-5" v-if="item.state == 'created'">
            <div @click.stop="cancel(item._id)">
              <i class="fas fa-times fa-2x text-error"></i>
            </div>
            <div class="ma" @click.stop="sign(item._id)">
              <i class="fas fa-check fa-lg text-success"></i>
            </div>
          </div>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import { loanObject } from "@/utils.js";
import { mapActions } from "vuex";

export default {
  components: {
    Table,
  },
  methods: {
    ...mapActions(["signLoan", "cancelLoan"]),
    sign(id) {
      this.signLoan(id)
        .then(() => {
          this.$emit("refresh");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel(id) {
      this.cancelLoan(id)
        .then(() => {
          this.$emit("refresh");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    customLoans() {
      return this.loans.map((loan) => {
        loan.predictedEndDate = loanObject.predictedEndDate(loan);
        return loan;
      });
    },
  },
  props: {
    loans: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
