import Vue from "vue";

export default {
    actions: {
        getVacations({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api
                    .get("/vacations/all")
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch("handle_response_error", error);
                        reject(error.response);
                    });
            });
        },
        getVacationsByUser({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                const params = id == null ? "" : "/" + id
                Vue.api
                    .get("/vacations/user" + params)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch("handle_response_error", error);
                        reject(error.response);
                    });
            });
        },
        getUnsignedVacations({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api
                    .get("/vacations/unsigned")
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch("handle_response_error", error);
                        reject(error.response);
                    });
            });
        },
        addVacation({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/vacations/add", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        signVacation({ dispatch }, vacation) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/vacations/sign", {
                    vacation: vacation
                }).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        cancelVacation({ dispatch }, vacation) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/vacations/cancel", {
                    vacation: vacation
                }).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getAvailablePaidVacations({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api
                    .get("/vacations/available/paid")
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch("handle_response_error", error);
                        reject(error.response);
                    });
            });
        },
        getAvailableStudyVacations({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api
                    .get("/vacations/available/study")
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch("handle_response_error", error);
                        reject(error.response);
                    });
            });
        },
    }
}