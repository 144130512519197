export default [
    {
        path: "/productiondays/staff/add/:id",
        props: true,
        name: "Add Production Crew",
        component: () => import("../views/projects/AddProductionStaff.vue")
    },
    {
        path: "/productiondays/edit/:id",
        props: true,
        name: "Edit Production Day",
        component: () => import("../views/projects/EditProduction.vue")
    },
    {
        path: "/productiondays/:id",
        props: true,
        name: "Production Day",
        component: () => import("../views/projects/ProductionDetail.vue")
    },
]