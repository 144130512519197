<template>
  <div class="v-stack h-stretch gap-3">
    <Table :items="vacations" :css="'80px 80px 60px 1fr 100px'" :columns="[
      {
        name: 'From',
        sort: 'date',
        path: ['startDate'],
        formatType: 'date',
        format: 'DD.MM.YYYY',
      },
      {
        name: 'To',
        sort: 'date',
        path: ['endDate'],
        formatType: 'date',
        format: 'DD.MM.YYYY',
      },
      {
        name: 'Type',
        sort: 'alphabet',
        path: ['type'],
      },
      {
        name: 'Description',
        sort: 'alphabet',
        path: ['description'],
      },
      {
        name: 'Signed',
        alignTitle: 'center',
      },
    ]">
      <template v-slot:Signed="{ item }">
        <div class="h-stack h-center">
          <div v-if="item.state == 'signed'">
            <i class="fas fa-check fa-lg text-success"></i>
          </div>
          <div v-if="item.state == 'canceled'">
            <i class="fas fa-times fa-2x text-error"></i>
          </div>
          <div class="h-stack gap-5" v-if="item.state == 'created'">
            <div @click.stop="cancel(item._id)">
              <i class="fas fa-times fa-2x text-error"></i>
            </div>
            <div class="ma" @click.stop="sign(item._id)">
              <i class="fas fa-check fa-lg text-success"></i>
            </div>
          </div>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Table,
  },
  methods: {
    ...mapActions(["signVacation", "cancelVacation"]),
    sign(id) {
      this.signVacation(id)
        .then(() => {
          this.$emit("refresh");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel(id) {
      this.cancelVacation(id)
        .then(() => {
          this.$emit("refresh");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  props: {
    vacations: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
