<template>
  <div>
    <CalendarPopup></CalendarPopup>
    <div class="v-stack h-stretch" v-if="dayonly">
      <CalendarDay v-model="date" :events="filteredEvents"></CalendarDay>
    </div>
    <div class="v-stack panel-content-right" v-if="!dayonly">
      <div class="panel-left v-start h-stretch">
        <button class="add" style="width: 100% !important" @click="goToToday()">
          {{ today.format("DD MMMM YYYY") }}
        </button>

        <CalendarSwitchButton v-model="mode" :items="[
          {
            title: 'Day',
            value: 'day',
          },
          {
            title: 'Week',
            value: 'week',
          },
          {
            title: 'Month',
            value: 'month',
          },
          {
            title: 'Year',
            value: 'year',
          },
        ]"></CalendarSwitchButton>
        <CalendarMonthPicker ref="calendarMonthPicker" v-model="date" :events="filteredEvents"></CalendarMonthPicker>
        <div class="h-stack h-space-between">
          <a class="heading-title-2" style="align-self: center">Personal</a>
          <input v-model="personal" type="checkbox" />
        </div>
        <select v-if="!personal" v-model="filter">
          <option value="all">Everyone</option>
          <option :value="user._id" v-for="user in crew" :key="user._id">
            {{ user.fullName }}
          </option>
        </select>
        <a class="heading-title-2">Event Types</a>
        <div class="v-stack gap-3 scroll h-stretch v-stretch calendar-event-types">
          <div v-for="(data, name) in eventTypes" :key="name" class="h-stack gap-3 h-start v-center"
            :style="'color:' + data.color">
            <input type="checkbox" v-model="data.show" />
            <i :class="data.icon"></i>
            <div>{{ name }}</div>
          </div>
        </div>
      </div>

      <div class="v-stack h-stretch">
        <CalendarViewDay v-if="mode == 'day'" v-model="date" :events="filteredEvents"></CalendarViewDay>
        <CalendarViewWeek v-if="mode == 'week'" v-model="date" @dateselected="setDay()" :events="filteredEvents">
        </CalendarViewWeek>
        <CalendarViewMonth v-if="mode == 'month'" v-model="date" @dateselected="setWeek()" :events="filteredEvents">
        </CalendarViewMonth>
        <CalendarViewYear v-if="mode == 'year'" v-model="date" :year="year" @monthchange="setMonth()"
          @dateselected="setWeek()" :events="filteredEvents"></CalendarViewYear>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarMonthPicker from "@/components/calendar/CalendarMonthPicker.vue";
import CalendarSwitchButton from "@/components/calendar/CalendarSwitchButton.vue";
import CalendarDay from "@/components/calendar/CalendarDay.vue";
import CalendarViewDay from "@/components/calendar/CalendarViewDay.vue";
import CalendarViewWeek from "@/components/calendar/CalendarViewWeek.vue";
import CalendarViewMonth from "@/components/calendar/CalendarViewMonth.vue";
import CalendarViewYear from "@/components/calendar/CalendarViewYear.vue";
import CalendarPopup from "@/components/calendar/CalendarPopup.vue";
import moment from "moment";
import { v4 as uuid } from "uuid";
import utils from "@/utils.js";
import { mapActions } from "vuex";

export default {
  beforeRouteEnter(to, from, next) {
    document.getElementById("page-footer").style.display = "none";
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.getElementById("page-footer").style.display = "grid";
    next();
  },
  data() {
    return {
      events: {},
      date: moment(),
      today: moment(),
      mode: "month",
      year: moment().year(),
      eventTypes: {},
      personal: false,
      crew: [],
      filter: "all",
    };
  },
  props: {
    dayonly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    date() {
      this.year = this.date.year();
    },
  },
  computed: {
    filteredEvents() {
      const filteredObject = {};

      for (const year in this.events) {
        filteredObject[year] = {};
        const yearValue = this.events[year];
        for (const month in yearValue) {
          filteredObject[year][month] = {};
          const monthValue = yearValue[month];
          for (const day in monthValue) {
            const dayValue = monthValue[day];
            filteredObject[year][month][day] = [];
            for (const event of dayValue) {
              if (this.eventTypes[event.eventType].show) {
                if (event.eventType == "birthday") {
                  filteredObject[year][month][day].push(event);
                } else if (this.personal) {
                  for (const user of event.crew) {
                    if (user._id == this.$store.state.id) {
                      filteredObject[year][month][day].push(event);
                      break;
                    }
                  }
                } else if (this.filter != "all") {
                  for (const user of event.crew) {
                    if (user._id == this.filter) {
                      filteredObject[year][month][day].push(event);
                      break;
                    }
                  }
                } else {
                  filteredObject[year][month][day].push(event);
                }
              }
            }
          }
        }
      }

      return filteredObject;
    },
  },
  methods: {
    ...mapActions(["getUsers", "getProjects", "getScheduleItems", "getVacations"]),
    createDescription(title, text, link = null, linkTitle = null) {
      return {
        title: title,
        text: text,
        link: link,
        linkTitle: linkTitle,
      };
    },
    createEvent(
      title,
      eventType,
      startDate,
      endDate = null,
      description = null,
      editable = true,
      url = null,
      crew = []
    ) {
      const event = {
        _id: uuid(),
        editable: editable,
        startDate: startDate,
        endDate: endDate,
        title: title,
        eventType: eventType,
        description: description,
        url: url,
        crew: crew,
      };

      this.addEventToDate(event, startDate);
      if (event.eventType == "birthday") {
        for (let i = 1; i < 500; i++) {
          this.addEventToDate(event, moment(startDate).add(i, "years"));
        }
      }

      if (event.eventType != "birthday" && event.endDate != null) {
        if (!event.startDate.isSame(event.endDate, "days")) {
          let date;
          let i = 0;

          do {
            i++;
            date = moment(event.startDate).add(i, "days");
            this.addEventToDate(event, date);
          } while (date.isBefore(event.endDate, "day"));
        }
      }
    },
    addEventToDate(event, date) {
      if (date.year().toString() in this.events == false) {
        this.$set(this.events, date.year().toString(), {});
      }

      const yearObject = this.events[date.year().toString()];
      if (date.month().toString() in yearObject == false) {
        this.$set(yearObject, date.month().toString(), {});
      }

      const monthObject = yearObject[date.month().toString()];
      if (date.date().toString() in monthObject == false) {
        this.$set(monthObject, date.date().toString(), []);
      }

      monthObject[date.date().toString()].push(event);
    },
    goToToday() {
      this.date = moment(this.today);
    },
    setMonth() {
      this.mode = "month";
    },
    setWeek() {
      this.mode = "week";
    },
    setDay() {
      this.mode = "day";
    },
  },
  mounted() {
    for (const key in utils.calendarObject.eventTypes) {
      const object = utils.calendarObject.eventTypes[key];
      this.$set(object, "show", true);
      this.$set(this.eventTypes, key, object);
    }

    // get birthdays
    this.getUsers()
      .then((users) => {
        const userMap = {};

        for (const user of users) {
          userMap[user._id] = user;

          if (user.accessLevel >= 5) {
            this.crew.push(user);
          }

          if (user.birthday && moment(user.birthday).isValid()) {
            this.createEvent(
              user.fullName,
              "birthday",
              moment(user.birthday),
              null,
              null,
              false,
              null //"/users/detail/" + user._id
            );
          }
        }

        // vacations
        this.getVacations().then((vacations) => {
          for (const vacation of vacations.filter((vacation) => vacation.state == "signed")) {
            const user = userMap[vacation.user]
            this.createEvent(
              user.fullName,
              "vacation",
              moment(vacation.startDate),
              moment(vacation.endDate),
              [this.createDescription(
                "Type",
                vacation.type
              ), this.createDescription(
                "Description",
                vacation.description
              )],
              false,
              null,
              [user]
            );
          }
        }).catch((error) => {
          console.log(error)
        })

        // sort stored crew
        this.crew = this.crew.sort((a, b) =>
          a.fullName.localeCompare(b.fullName, "cs")
        );

        // deadlines && recording days
        this.getProjects().then((projects) => {
          for (const project of projects) {
            // deadline
            if (project.deadline && moment(project.deadline).isValid()) {
              const crew = [];
              for (const staff of project.postproductionStaff) {
                crew.push(userMap[staff.user]);
              }

              this.createEvent(
                `${project.client.name} / ${project.title} / ${project.subtitle}`,
                "deadline",
                moment(project.deadline),
                null,
                [
                  this.createDescription(
                    "Project description",
                    project.description,
                    "/projects/detail/" + project._id,
                    "Go to Project"
                  ),
                ],
                false,
                null, //"/projects/detail/" + project._id,
                crew
              );

              // recording days
              for (const day of project.recordingDays) {
                const endDate = moment(day.endDate);

                const crew = [];
                for (const staff of day.staff) {
                  crew.push(userMap[staff.user]);
                }

                this.createEvent(
                  `${project.client.name} / ${project.title} / ${project.subtitle}`,
                  "production",
                  moment(day.startDate),
                  moment(day.startDate)
                    .hours(endDate.hours())
                    .minutes(endDate.minutes()),
                  [
                    this.createDescription(
                      "Day description",
                      day.description,
                      "/projects/detail/" + project._id + "/crew",
                      "Go to Production"
                    ),
                    this.createDescription(
                      "Address",
                      day.address,
                      "http://maps.google.com/maps?q=" +
                      day.address.replace(/\n/g, " "),
                      "Go to gMaps"
                    ),
                    this.createDescription(
                      "Project description",
                      project.description,
                      "/projects/detail/" + project._id,
                      "Go to Project"
                    ),
                  ],
                  false,
                  null, //"/projects/detail/" + project._id + "/crew",
                  crew
                );
              }
            }
          }
        });

        // postproduction
        this.getScheduleItems().then((items) => {
          const itemsSorted = items.sort((a, b) => moment(a.date) - moment(b.date))
          let time = null
          let day = null
          for (const item of itemsSorted) {
            if (time == null) {
              time = 9
            }

            if (day == null) {
              day = moment(item.date)
            } else {
              if (!moment(item.date).isSame(day, "day")) {
                time = 9
                day = moment(item.date)
              }
            }

            this.createEvent(
              item.project.name,
              "postproduction",
              moment(item.date).hours(time).minutes(0).seconds(0).milliseconds(0),
              moment(item.date).hours(time + item.hours).minutes(0).seconds(0).milliseconds(0),
              [
                this.createDescription(
                  "Role",
                  item.role
                ),
              ],
              false,
              null,
              [userMap[item.user]]
            )

            time += item.hours
          }
        }).catch((error) => {
          console.log(error)
        })
      })
      .catch((error) => {
        console.log(error);
      });
  },
  components: {
    CalendarMonthPicker,
    CalendarSwitchButton,
    CalendarDay,
    CalendarViewDay,
    CalendarViewWeek,
    CalendarViewMonth,
    CalendarViewYear,
    CalendarPopup,
  },
};
</script>

<style lang="scss" scoped>
.calendar-event-types div {
  align-self: center;
}

.calendar-event-types i {
  width: 20px;
  align-self: center;
}

.panel-left {
  width: 260px;
}

.panel-content-right {
  margin-left: 260 + 40px;
  width: calc(100% - 260px - 40px);
}

.scroll {
  overflow-y: auto;
}

.heading-title-2 {
  padding: 0px;
  padding-bottom: 6px;
}
</style>
