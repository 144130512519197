import Vue from 'vue'

export default {
    actions: {
        getClients({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/clients").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getClient({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/clients/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addClient({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/clients/add", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        editClient({ dispatch }, client) {
            return new Promise((resolve, reject) => {
                Vue.api.patch("/clients/" + client._id, client).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
    }
}