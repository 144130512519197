<template>
  <div class="h-stack h-stretch">
    <div class="h-stack calendarviewday-title h-stretch">
      <div
        class="calendarviewday-title-leftarrow calendarviewday-link"
        @click="addDay(-1)"
      >
        <i class="fas fa-angle-left"></i>
      </div>
      <div
        class="calendarviewday-title-rightarrow calendarviewday-link"
        @click="addDay(1)"
      >
        <i class="fas fa-angle-right"></i>
      </div>
      <div class="v-stack gap-3 h-stretch">
        <div class="calendarviewday-title-name">{{ date.format("dd") }}</div>
        <div class="calendarviewday-title-date">{{ date.format("DD") }}</div>
      </div>
    </div>
    <div class="h-stack h-stretch calendarviewday-container">
      <CalendarDay :date="date" :events="events"></CalendarDay>
    </div>
  </div>
</template>

<script>
import CalendarDay from "@/components/calendar/CalendarDay.vue";
import moment from "moment";

export default {
  components: {
    CalendarDay,
  },
  model: {
    prop: "date",
    event: "datechange",
  },
  methods: {
    addDay(amount) {
      this.$emit("datechange", moment(this.date).add(amount, "days"));
    },
  },
  props: {
    date: {
      type: Object,
      default: () => moment(),
    },
    events: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.calendarviewday-title {
  position: fixed;
  width: calc(100% - 520px - 40px - 40px);
  padding-left: 40px;
  margin-top: -60px;
  padding-bottom: 10px;
  background-color: white;
  border-bottom: 1px lightgray solid;
  z-index: 4;
}

html:not([data-scroll="0"]) .calendarviewday-title {
  border-bottom: 2px lightgray solid;
}

.calendarviewday-title-name {
  padding-top: 40px;
  color: grey;
  font-size: 18px;
}
.calendarviewday-title-date {
  color: grey;
  font-weight: bold;
  font-size: 26px;
}

.calendarviewday-container {
  margin-top: 55px;
}

.calendarviewday-title-leftarrow {
  position: absolute;
  top: 60px;
  left: 0px;
}

.calendarviewday-title-rightarrow {
  position: absolute;
  top: 60px;
  right: 0px;
}

.calendarviewday-link {
  user-select: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
