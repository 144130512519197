<template>
  <CalendarMonthBase
    :year="year"
    :month="month"
    :highlightToday="highlightToday"
    :highlightSelected="highlightSelected"
    :showOverflow="showOverflow"
    :date="date"
    @datechange="$emit('datechange', $event)"
    @dateselected="$emit('dateselected')"
    :events="events"
  >
    <slot></slot>
  </CalendarMonthBase>
</template>

<script>
import moment from "moment";
import CalendarMonthBase from "@/components/calendar/CalendarMonthBase";

export default {
  components: {
    CalendarMonthBase,
  },
  props: {
    events: {
      type: Object,
      default: () => {
        return {};
      },
    },
    date: {
      type: Object,
      default: () => moment(),
    },
    month: {
      type: Number,
      default: moment().month(),
    },
    year: {
      type: Number,
      default: moment().year(),
    },
    highlightToday: {
      type: Boolean,
      default: true,
    },
    highlightSelected: {
      type: Boolean,
      default: true,
    },
    showOverflow: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: "date",
    event: "datechange",
  },
};
</script>

<style lang="scss" scoped></style>
