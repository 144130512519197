<template>
  <div class="v-stack h-stretch v-stretch">
    <div class="pane-top gap-3">
      <div class="h-stack h-space-between">
        <div class="calendaviewmonth-link" @click="addMonth(-1)">
          <i class="fas fa-angle-left"></i>
        </div>
        <div class="calendarviewmonth-title">
          {{ date.format("MMMM YYYY") }}
        </div>
        <div class="calendaviewmonth-link" @click="addMonth(1)">
          <i class="fas fa-angle-right"></i>
        </div>
      </div>
      <CalendarMonthBase
        class="calendarviewmonth-fullscreen"
        :fullscreen="true"
        :date="date"
        @datechange="$emit('datechange', $event)"
        @dateselected="$emit('dateselected', $event)"
        :year="date.year()"
        :month="date.month()"
        :highlightSelected="false"
        :events="events"
      ></CalendarMonthBase>
    </div>
  </div>
</template>

<script>
import CalendarMonthBase from "@/components/calendar/CalendarMonthBase";
import moment from "moment";

export default {
  components: {
    CalendarMonthBase,
  },
  props: {
    date: {
      type: Object,
      default: () => moment(),
    },
    events: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  model: {
    prop: "date",
    event: "datechange",
  },
  methods: {
    addMonth(amount) {
      this.$emit("datechange", moment(this.date).add(amount, "months"));
    },
  },
};
</script>

<style lang="scss" scoped>
.calendarviewmonth-fullscreen {
  height: calc(100vh - 182px);
}

.calendarviewmonth-title {
  min-width: 160px;
}

.pane-top {
  height: 100%;
}

.calendaviewmonth-link {
  user-select: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
