const detailChildren = [
  {
    props: true,
    path: "overview",
    component: () => import("../views/users/detail/UserDetailOverview.vue"),
  },
  {
    props: true,
    path: "hours",
    component: () => import("../views/users/detail/FinanceMyHours.vue"),
  },
  {
    props: true,
    path: "pay",
    component: () => import("../views/users/detail/FinanceMyPay.vue"),
  },
  {
    props: true,
    path: "loans",
    component: () => import("../views/users/detail/FinanceMyLoans.vue"),
  },
  {
    props: true,
    path: "vacation",
    component: () => import("../views/users/detail/UserVacation.vue"),
  },
];

export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("../views/users/detail/UserDetail.vue"),
    children: detailChildren,
  },
  {
    path: "/users/detail/:id",
    props: true,
    name: "Show",
    component: () => import("../views/users/detail/UserDetail.vue"),
    children: detailChildren,
  },
  {
    path: "/users/vacation/new/:id",
    props: true,
    name: "New Vacation",
    component: () => import("../views/users/NewVacation.vue"),
  },
  {
    path: "/crew",
    name: "lifeCrew",
    component: () => import("../views/users/Crew.vue"),
  },
  {
    path: "/freelancers",
    name: "Freelancers",
    component: () => import("../views/users/Freelancers.vue"),
  },
  {
    path: "/customers",
    name: "Customers",
    component: () => import("../views/users/Customers.vue"),
  },
  {
    path: "/users/crew/add",
    name: "Add Crew",
    component: () => import("../views/users/AddCrew.vue"),
  },
  {
    path: "/users/freelancers/add",
    name: "Add Freelancer",
    component: () => import("../views/users/AddFreelancer.vue"),
  },
  {
    path: "/users/customers/add",
    name: "Add Customer",
    component: () => import("../views/users/AddCustomer.vue"),
  },
  {
    path: "/users/edit/:id",
    props: true,
    name: "Edit",
    component: () => import("../views/users/EditUser.vue"),
  },
  {
    path: "/users/workday/new/:id",
    props: true,
    name: "Add Workday",
    component: () => import("../views/users/NewWorkDay.vue"),
  },
  {
    path: "/workday/:id",
    props: true,
    name: "Show Workday",
    component: () => import("../views/users/WorkDayDetail.vue"),
  },
  {
    path: "/users/pay/new/:id",
    props: true,
    name: "Add Pay",
    component: () => import("../views/users/AddPay.vue"),
  },
  {
    path: "/users/bonus/new/:id",
    props: true,
    name: "Add Bonus",
    component: () => import("../views/users/AddBonus.vue"),
  },
  {
    path: "/users/advance/new/:id",
    props: true,
    name: "Add Advance",
    component: () => import("../views/users/AddAdvance.vue"),
  },
  {
    path: "/users/fine/new/:id",
    props: true,
    name: "Add Fine",
    component: () => import("../views/users/AddFine.vue"),
  },
  {
    path: "/loans/pay/:id",
    props: true,
    name: "Pay Loan",
    component: () => import("../views/users/PayLoan.vue"),
  },
];
