import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";

import userRoutes from "./user";
import clientRoutes from "./client";
import projectRoutes from "./project";
import productionDaysRoutes from "./productionday";
import pricelistRoutes from "./pricelist";
import storageRoutes from "./storage";
import xFilesRoutes from "./xfiles";
import lifeSchoolRoutes from "./lifeschool";
import financeRoutes from "./finance";
import carRoutes from "./cars";
import systemRoutes from "./system";

Vue.use(VueRouter);

const routes = [
  ...userRoutes,
  ...clientRoutes,
  ...projectRoutes,
  ...productionDaysRoutes,
  ...pricelistRoutes,
  ...storageRoutes,
  ...xFilesRoutes,
  ...lifeSchoolRoutes,
  ...financeRoutes,
  ...carRoutes,
  ...systemRoutes,
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/calendar",
    name: "CalendarOld",
    component: () => import("../views/CalendarOld.vue"),
  },
  {
    path: "/calendar/calendar",
    name: "Calendar",
    component: () => import("../views/calendar/Calendar.vue"),
  },
  {
    path: "/calendar/planner",
    name: "Planner",
    component: () => import("../views/calendar/Planner.vue"),
  },
  {
    path: "/notes",
    name: "lifeNotes",
    component: () => import("../views/Notes.vue"),
  },
  {
    path: "/lifechat",
    name: "lifeChat",
    component: () => import("../views/LifeChat.vue"),
  },
  {
    path: "/rental",
    name: "Rental",
    component: () => import("../views/rental/Rental.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
