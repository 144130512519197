import Vue from 'vue'

export default {
    actions: {
        getDrives({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/drive").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getDrive({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/drive/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addDrive({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/drive", data).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        updateDrive({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.patch("/drive/" + data._id, data).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
    }
}