<template>
  <div class="v-stack h-stretch gap-3">
    <div class="table">
      <div class="table-header onetimepay-row">
        <div class="text-left">Date</div>
        <div class="text-left" v-if="showName">Name</div>
        <div class="text-right">Amount</div>
        <div class="text-left">Description</div>
        <div class="text-left">Signed</div>
      </div>
      <div
        v-for="pay in oneTimePay"
        :key="pay._id"
        class="table-row onetimepay-row"
      >
        <div class="text-left">{{ formatDate(pay.date, "DD.MM.YYYY") }}</div>
        <div v-if="showName" class="text-left">
          {{ pay.user.firstName }} {{ pay.user.lastName }}
        </div>
        <div class="text-right">{{ format.currency(pay.amount) }} Kč</div>
        <div class="text-left">{{ pay.description }}</div>
        <div class="h-stack h-start">
          <div v-if="pay.state == 'signed'">
            <i class="fas fa-check fa-lg text-success"></i>
          </div>
          <div v-if="pay.state == 'canceled'">
            <i class="fas fa-times fa-2x text-error"></i>
          </div>
          <div class="h-stack gap-5" v-if="allowSign && pay.state == 'created'">
            <div @click.stop="cancel(pay._id)">
              <i class="fas fa-times fa-2x text-error"></i>
            </div>
            <div class="ma" @click.stop="sign(pay._id)">
              <i class="fas fa-check fa-lg text-success"></i>
            </div>
          </div>
        </div>
      </div>
      <div v-if="oneTimePay.length == 0" class="table-row">
        <div>{{ emptyMessage }}</div>
      </div>
    </div>
    <div class="h-stack h-end gap-3">
      <button v-if="user" @click="addNew()" class="add">Add</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import utils from "@/utils.js";
const moment = require("moment");

export default {
  data() {
    return {
      format: utils.format,
    };
  },
  props: {
    bonus: {
      type: Boolean,
      default: true,
    },
    allowSign: {
      type: Boolean,
      default: false,
    },
    showName: {
      type: Boolean,
      default: false,
    },
    oneTimePay: {
      type: Array,
      default: () => [],
    },
    emptyMessage: {
      type: String,
      default: "No Data",
    },
    user: {
      type: String,
      default: null,
    },
  },
  methods: {
    ...mapActions(["signOneTimePay", "cancelOneTimePay"]),
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
    sign(oneTimePayId) {
      this.signOneTimePay(oneTimePayId)
        .then(() => {
          this.$emit("refresh");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel(oneTimePayId) {
      this.cancelOneTimePay(oneTimePayId)
        .then(() => {
          this.$emit("refresh");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addNew() {
      if (this.bonus) {
        this.$router.push("/users/bonus/new/" + this.user);
      } else {
        this.$router.push("/users/advance/new/" + this.user);
      }
    },
  },
};
</script>

<style scoped>
.onetimepay-row {
  grid-auto-columns: 1fr 1fr 1fr 1fr 1fr !important;
  gap: 10px;
}
</style>
