<template>
  <div class="v-stack h-stretch gap-3">
    <div class="table v-start" :style="height ? `height: ${height}px;` : ''">
      <div class="table-header row" v-bind:style="styleRowHeader()">
        <div v-bind:class="[
          title.alignTitle ? '' : 'text-left',
          title.formatType == 'currency' ? 'currency' : '',
        ]" v-for="title in columns" :key="title.name" @click="sort(title)" v-bind:style="[
  title.alignTitle ? 'text-align: ' + title.alignTitle : '',
]">
          {{ title.name }}
          <template v-if="selectedTitle == title && ascending">&#9660;</template>
          <template v-if="selectedTitle == title && !ascending">&#9650;</template>
        </div>
      </div>
      <div v-for="item in sortedItems.slice(
        dynamicLimit * page,
        dynamicLimit * page + dynamicLimit
      )" :key="item._id" class="table-row row"
        v-bind:class="[typeof item.tableColumns == 'object' ? 'auto-height' : '']" v-bind:style="
          styleRow(item)
        ">
        <div @click.cancel="handleClick(item, title)" class="text-left" v-for="title in columns" :key="title.name"
          v-bind:class="[
            title.formatType == 'link' ? 'link' : '',
            title.formatType == 'copy' ? 'copy' : '',
            title.formatType == 'currency' ? 'currency' : '',
            typeof item.tableColumns == 'object' ? 'auto-height' : ''
          ]" :style="title.styleFunction ? title.styleFunction(item) : ''">
          <slot :name="title.name" v-bind:item="item"></slot>
          {{ getItemValue(title, item) }}
          <template v-if="typeof item.tableColumns == 'object'">
            <div class="sub-row" v-for="column in item.tableColumns[title.name]" :key="column._id">{{ column.text }}
            </div>
          </template>
        </div>
      </div>

      <div v-if="sortedItems.length == 0" class="table-row">No Data</div>
    </div>
    <div class="h-stack gap-3 columns-3" v-if="pageable">
      <div class="h-stack gap-5">
        <div @click="goToPage(0)">
          <i v-bind:class="[page == 0 ? 'disabled' : '']" class="fas fa-angle-double-left"></i>
        </div>
        <div @click="previousPage()">
          <i v-bind:class="[page == 0 ? 'disabled' : '']" class="fas fa-arrow-left"></i>
        </div>
        <div v-bind:class="[
          index - 1 + pageOffset == page ? 'active-page' : 'page-link',
        ]" @click="goToPage(index - 1 + pageOffset)" style="width: 20px"
          v-for="index in Math.min(numberOfPages + 1, maxPageShortcut)" :key="index">
          {{ index + pageOffset }}
        </div>

        <div @click="nextPage()">
          <i v-bind:class="[page == numberOfPages ? 'disabled' : '']" class="fas fa-arrow-right"></i>
        </div>
        <div @click="goToPage(numberOfPages)">
          <i v-bind:class="[page == numberOfPages ? 'disabled' : '']" class="fas fa-angle-double-right"></i>
        </div>
      </div>
      <div></div>
    </div>
    <div v-if="expandable && this.sortedItems.length > this.limit" class="ma table-header" @click="handleExpand()">
      <template v-if="!expanded">More &#9660;</template>
      <template v-if="expanded">Less &#9650;</template>
    </div>
    <div v-if="showCount">Count: {{ sortedItems.length }}</div>
  </div>
</template>

<script>
const moment = require("moment");
import EventBus from "@/eventbus.js";
import utils from "@/utils.js";
import Vue from "vue";

export default {
  props: {
    showCount: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    defaultColumn: {
      type: Number,
      default: -1,
    },
    limit: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    pageable: {
      type: Boolean,
      default: false,
    },
    css: {
      type: String,
      default: "1fr",
    },
    defaultAscending: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: null,
    },
    styleFunction: {
      type: Function,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      sortedItems: [],
      selectedTitle: null,
      ascending: true,
      expanded: false,
      page: 0,
      maxPageShortcut: 7,
      format: utils.format,
      allowDefaultClick: true,
    };
  },
  watch: {
    items() {
      this.applySort();
    },
  },
  computed: {
    autoHeight() {
      for (const title of this.columns) {
        if (Array.isArray(title.columns)) {
          return true
        }
      }
      return false
    },
    pageOffset() {
      if (this.numberOfPages < this.maxPageShortcut) {
        return 0;
      }
      if (this.page < Math.floor(this.maxPageShortcut / 2)) {
        return 0;
      }
      if (
        this.page >
        this.numberOfPages - Math.floor(this.maxPageShortcut / 2)
      ) {
        return this.numberOfPages - this.maxPageShortcut + 1;
      }
      return this.page - Math.floor(this.maxPageShortcut / 2);
    },
    numberOfPages() {
      return Math.floor(this.sortedItems.length / this.limit);
    },
    dynamicLimit() {
      if (this.expanded && this.expandable) {
        return Number.MAX_SAFE_INTEGER;
      }
      return this.limit;
    },
  },
  methods: {
    styleRowHeader() {
      if (this.$isMobile()) {
        return {}
      }
      return {
        'grid-auto-columns': this.css + ' !important'
      }
    },
    styleRow(item) {
      item
      // console.log(this.styleFunction(item))
      if (this.$isMobile()) {
        return {}
      }
      return {
        'grid-auto-columns': this.css + ' !important'
      }
    },
    isMobile() {
      return this.$isMobile()
    },
    openLink(link) {
      let url = link;

      if (!url.startsWith("http")) {
        url = "https://" + url;
      }

      window.open(url, "_blank");
    },
    handleClick(item, title) {
      if (!this.allowDefaultClick) {
        this.allowDefaultClick = true;
        return;
      }

      if (title) {
        if (title.formatType == "link") {
          this.openLink(this.getItemValue(title, item));
        } else if (title.formatType == "copy") {
          navigator.clipboard.writeText(this.getItemValue(title, item));
          EventBus.$emit("message", {
            text: "Copied to clipboard!",
          });
        } else {
          this.$emit("rowSelected", item);
        }
      } else {
        this.$emit("rowSelected", item);
      }
    },
    handleExpand() {
      if (this.expandable) {
        this.expanded = !this.expanded;
      }
    },
    getItemValue(title, item) {
      let value = null;
      if (title.path) {
        value = this.reduce(item, title.path);
      }

      if (title.formatMap) {
        value = title.formatMap(value == null ? item : value);
      }

      if (title.format && title.formatType == "date") {
        value = this.formatDate(value, title.format);
      } else if (title.formatType == "array") {
        if (title.format) {
          value = this.reduce(item, title.path).map((item) =>
            this.reduce(item, title.format)
          );
        }

        value = value.join(", ");
      } else if (title.formatType == "count") {
        value = value.length;
      } else if (title.formatType == "phone") {
        value = this.format.telephone(value);
      } else if (title.formatType == "currency") {
        value = this.format.currency(value);

        if (title.format == "czk") {
          value = value + " Kč";
        }
      } else if (title.formatType == "boolean") {
        value = value ? title.format[0] : title.format[1];
      } else if (title.format) {
        value = value + " " + title.format;
      }

      return value;
    },
    sort(title) {
      if (title.sort) {
        if (this.selectedTitle != title) {
          this.selectedTitle = title;
        } else {
          this.ascending = !this.ascending;
        }

        this.applySort();
      }
    },
    applySort() {
      this.sortedItems = [...this.items];

      if (!this.selectedTitle) {
        if (this.defaultColumn >= 0 && this.defaultColumn < this.items.length) {
          this.selectedTitle = this.columns[this.defaultColumn];
        } else {
          return;
        }
      }

      if (this.selectedTitle.sort == "alphabet") {
        if (this.selectedTitle.formatType == "boolean") {
          if (this.ascending) {
            this.sortedItems.sort((a, b) => {
              return this.reduce(
                a,
                this.selectedTitle.path,
                this.selectedTitle
              ) === this.reduce(b, this.selectedTitle.path, this.selectedTitle)
                ? 0
                : this.reduce(a, this.selectedTitle.path, this.selectedTitle)
                  ? -1
                  : 1;
            });
          } else {
            this.sortedItems.sort((a, b) => {
              return this.reduce(
                b,
                this.selectedTitle.path,
                this.selectedTitle
              ) === this.reduce(a, this.selectedTitle.path, this.selectedTitle)
                ? 0
                : this.reduce(b, this.selectedTitle.path, this.selectedTitle)
                  ? -1
                  : 1;
            });
          }
        } else {
          if (this.ascending) {
            this.sortedItems.sort((a, b) => {
              return this.reduce(a, this.selectedTitle.path, this.selectedTitle)
                .toLowerCase()
                .localeCompare(
                  this.reduce(
                    b,
                    this.selectedTitle.path,
                    this.selectedTitle
                  ).toLowerCase(),
                  "cs"
                );
            });
          } else {
            this.sortedItems.sort((a, b) => {
              return this.reduce(b, this.selectedTitle.path, this.selectedTitle)
                .toLowerCase()
                .localeCompare(
                  this.reduce(
                    a,
                    this.selectedTitle.path,
                    this.selectedTitle
                  ).toLowerCase(),
                  "cs"
                );
            });
          }
        }
      } else if (this.selectedTitle.sort == "numeric") {
        if (this.selectedTitle.formatType == "count") {
          if (this.ascending) {
            this.sortedItems.sort((a, b) => {
              return (
                this.reduce(a, this.selectedTitle.path, this.selectedTitle)
                  .length -
                this.reduce(b, this.selectedTitle.path, this.selectedTitle)
                  .length
              );
            });
          } else {
            this.sortedItems.sort((a, b) => {
              return (
                this.reduce(b, this.selectedTitle.path, this.selectedTitle)
                  .length -
                this.reduce(a, this.selectedTitle.path, this.selectedTitle)
                  .length
              );
            });
          }
        } else {
          if (this.ascending) {
            this.sortedItems.sort((a, b) => {
              return (
                this.reduce(a, this.selectedTitle.path, this.selectedTitle) -
                this.reduce(b, this.selectedTitle.path, this.selectedTitle)
              );
            });
          } else {
            this.sortedItems.sort((a, b) => {
              return (
                this.reduce(b, this.selectedTitle.path, this.selectedTitle) -
                this.reduce(a, this.selectedTitle.path, this.selectedTitle)
              );
            });
          }
        }
      } else if (this.selectedTitle.sort == "date") {
        if (!this.ascending) {
          this.sortedItems.sort((a, b) => {
            if (
              moment(
                this.reduce(a, this.selectedTitle.path, this.selectedTitle)
              ).isBefore(
                moment(
                  this.reduce(b, this.selectedTitle.path, this.selectedTitle)
                )
              )
            ) {
              return -1;
            } else if (
              moment(
                this.reduce(a, this.selectedTitle.path, this.selectedTitle)
              ).isAfter(
                moment(
                  this.reduce(b, this.selectedTitle.path, this.selectedTitle)
                )
              )
            ) {
              return 1;
            } else {
              return 0;
            }
          });
        } else {
          this.sortedItems.sort((a, b) => {
            if (
              moment(
                this.reduce(b, this.selectedTitle.path, this.selectedTitle)
              ).isBefore(
                moment(
                  this.reduce(a, this.selectedTitle.path, this.selectedTitle)
                )
              )
            ) {
              return -1;
            } else if (
              moment(
                this.reduce(b, this.selectedTitle.path, this.selectedTitle)
              ).isAfter(
                moment(
                  this.reduce(a, this.selectedTitle.path, this.selectedTitle)
                )
              )
            ) {
              return 1;
            } else {
              return 0;
            }
          });
        }
      }
    },
    reduce(item, path, title) {
      if (path) {
        return path.reduce((a, b) => {
          if (a) {
            return a[b];
          } else {
            return "";
          }
        }, item);
      }

      if (title && title.formatMap) {
        return title.formatMap(item);
      }

      return "";
    },
    formatDate(dateString, format) {
      if (dateString) {
        const date = moment(dateString);
        return date.format(format);
      } else {
        return null;
      }
    },
    nextPage() {
      if (this.page < this.numberOfPages) {
        this.page++;
      }
    },
    previousPage() {
      if (this.page > 0) {
        this.page--;
      }
    },
    goToPage(index) {
      if (index >= 0 && index < this.sortedItems.length) {
        this.page = index;
      }
    },
  },
  mounted() {
    Vue.router.beforeEach((to, from, next) => {
      to;
      from;
      this.allowDefaultClick = false;
      next();
    });

    this.ascending = this.defaultAscending;
    this.applySort();
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/main.scss";

.sub-row {
  padding-top: 3px;
  color: rgb(141, 141, 141) !important;
}

.active-page {
  font-weight: bold;
}

.page-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.row {
  gap: 10px;
}

.table-header {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.link {
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    text-decoration: underline;
  }
}

.table-row div {
  white-space: nowrap;
  overflow: hidden;
}

.auto-height {
  min-height: unset !important;
  // align-self: unset !important;
  padding-top: 6px;
  padding-bottom: 6px;
}

.copy {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    text-decoration: underline;
  }
}

.table-footer {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  height: 1px;
  width: calc(100% - 30px);
  border-top: 1px solid $color-separator;
  padding-top: 15px;
  padding-bottom: 48px;
}

i.disabled {
  color: grey !important;
}

.table {
  margin-bottom: 40px;
}

.currency {
  text-align: right;
}
</style>
