import Vue from "vue";

export default {
  actions: {
    addPricelistItem({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .post("/pricelist/item", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getPricelistItem({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/pricelist/item/" + id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getPricelistItems({ dispatch }) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/pricelist/item")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    updatePricelistItem({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .patch("/pricelist/item/" + data._id, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    removePricelistItem({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .delete(
            "/pricelist/item/" + data._id + "?pricelist=" + data.pricelist
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    addPricelist({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .post("/pricelist/list", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getPricelist({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/pricelist/list/" + id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getPricelists({ dispatch }) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/pricelist/list")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    updatePricelist({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .patch("/pricelist/list/" + data._id, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    addGearItem({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .post("/pricelist/gearitem/", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    editGearItem({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .patch("/pricelist/gearitem/" + data._id, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    fetchGearItem({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/pricelist/gearitem/" + id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    removeGearItem({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        Vue.api
          .delete("/pricelist/gearitem/" + id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    reorderPricelistItem({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .patch("/pricelist/reorder", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
  },
};
