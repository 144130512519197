import Vue from "vue";

export default {
  actions: {
    login({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .post("/users/login", data)
          .then((response) => {
            commit("setUser", {
              token: response.data.token,
              id: response.data.id,
              accessLevel: response.data.accessLevel,
              name: response.data.name,
            });
            resolve(response);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    logout({ commit }) {
      commit("setUser", {
        token: null,
        id: null,
        accessLevel: null,
        name: null,
      });
    },
    getUsers({ dispatch }) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/users")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getCrew({ dispatch }) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/users/crew")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getUsersByClient({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/user/client/" + id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getUser({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/users/" + id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    addUser({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .post("/users/add", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    addUserPayout({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .post("/users/payout", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getPayout({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/users/payout/" + id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    getNotifications({ dispatch }) {
      return new Promise((resolve, reject) => {
        Vue.api
          .get("/notifications")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    markNotificationAsRead({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        Vue.api
          .patch("/notifications/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    updateUser({ dispatch }, user) {
      return new Promise((resolve, reject) => {
        Vue.api
          .patch("/users/" + user._id, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    lockUser({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        Vue.api
          .patch("/users/lock/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    changePassword({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .patch("/password", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
    resetPassword({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.api
          .patch("/resetpassword", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
  },
};
