<template>
  <div class="v-stack gap-3 h-stretch v-start panel-content-left">
    <div class="panel-right v-stack v-start h-stretch">
      <a class="heading-title-1">{{ date.format("DD. MM. YYYY") }}</a>
      <a class="heading-title-1" style="padding-top: 0px">{{
          date.format("dddd")
      }}</a>
      <Calendar :dayonly="true" ref="calendar"></Calendar>
    </div>

    <template v-if="$store.state.accessLevel > 9">
      <a class="heading-title-1">Unsigned</a>
      <template v-if="unsignedVacations.length != 0">
        <a class="heading-title-2">Vacations</a>
        <TableVacations :vacations="unsignedVacations" v-on:refresh="refreshUnsignedVacations()"></TableVacations>
      </template>
      <template v-if="unsignedLoans.length != 0">
        <a class="heading-title-2">Company Loans</a>
        <TableLoans :loans="unsignedLoans" v-on:refresh="refreshUnsignedLoans()"></TableLoans>
      </template>
      <template v-if="unsignedWorkdays.length != 0">
        <a class="heading-title-2">Workdays</a>
        <TableWorkdays :showName="true" :workdays="unsignedWorkdays" emptyMessage="Everything is signed"
          :allowSign="true" v-on:refresh="refreshUnsignedWorkdays()"></TableWorkdays>
      </template>
      <template v-if="unsignedBonus.length != 0">
        <a class="heading-title-2">Bonus</a>
        <TableOneTimePay :showName="true" :oneTimePay="unsignedBonus" v-on:refresh="refreshUnsignedBonus()"
          :allowSign="true" emptyMessage="Everything is signed"></TableOneTimePay>
      </template>
      <template v-if="unsignedAdvance.length != 0">
        <a class="heading-title-2">Advance</a>
        <TableOneTimePay :showName="true" :oneTimePay="unsignedAdvance" v-on:refresh="refreshUnsignedAdvance()"
          :allowSign="true" emptyMessage="Everything is signed"></TableOneTimePay>
      </template>
      <div class="block" v-if="unsignedCount == 0">Nothing to sign</div>
    </template>

    <a class="heading-title-1">Projects</a>
    <a class="heading-title-2">Assigned</a>
    <Table :items="assignedProjects" :defaultColumn="0" :limit="5" :expandable="true"
      v-on:rowSelected="$router.push('/projects/detail/' + $event.project._id)" css="120px 80px 60px 1fr 1fr" :columns="[
        {
          name: 'Project Created',
          sort: 'date',
          path: ['createdDate'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Deadline',
          sort: 'date',
          path: ['deadline'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Icon',
          sort: 'alphabet',
          path: ['icon'],
        },
        {
          name: 'Project',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Role',
          path: ['roles'],
          formatType: 'array',
        },
      ]">
    </Table>
    <a class="heading-title-2">Shooting</a>
    <Table :items="recordingDays" :defaultColumn="0" :limit="5" :expandable="true" :defaultAscending="false"
      css="80px 60px 60px 60px 2fr 1fr 1fr" v-on:rowSelected="$router.push('/productiondays/' + $event._id)" :columns="[
        {
          name: 'Date',
          sort: 'date',
          path: ['startDate'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'From',
          sort: 'date',
          path: ['startDate'],
          formatType: 'date',
          format: 'HH:mm',
        },
        {
          name: 'To',
          sort: 'date',
          path: ['endDate'],
          formatType: 'date',
          format: 'HH:mm',
        },
        {
          name: 'Icon',
          sort: 'alphabet',
          path: ['project', 'icon'],
        },
        {
          name: 'Project',
          sort: 'alphabet',
          path: ['project', 'name'],
        },
        {
          name: 'Address',
          sort: 'alphabet',
          path: ['address'],
        },
      ]">
    </Table>
    <a class="heading-title-2">Deadlines</a>
    <Table :items="deadlineProjects" :defaultColumn="1" :limit="5" :expandable="true" :defaultAscending="false"
      css="60px 80px 60px 1fr 1fr" v-on:rowSelected="$router.push('/projects/detail/' + $event._id)" :columns="[
        {
          name: 'State',
          sort: 'alphabet',
          path: ['state'],
        },
        {
          name: 'Deadline',
          sort: 'date',
          path: ['deadline'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Icon',
          sort: 'alphabet',
          path: ['icon'],
        },
        {
          name: 'Project',
          sort: 'alphabet',
          path: ['name'],
        },
      ]">
    </Table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TableWorkdays from "@/components/TableWorkdays.vue";
import TableOneTimePay from "@/components/TableOneTimePay.vue";
import TableLoans from "@/components/TableLoans.vue";
import TableVacations from "@/components/TableVacations.vue";
import Table from "@/components/Table.vue";
import Calendar from "@/views/calendar/Calendar.vue";
const moment = require("moment");

export default {
  data() {
    return {
      unsignedLoans: [],
      unsignedWorkdays: [],
      unsignedBonus: [],
      unsignedAdvance: [],
      unsignedVacations: [],
      recordingDays: [],
      assignedProjects: [],
      deadlineProjects: [],
      date: moment(),
    };
  },
  components: {
    Calendar,
    TableWorkdays,
    TableOneTimePay,
    TableLoans,
    TableVacations,
    Table,
  },
  computed: {
    unsignedCount() {
      return (
        this.unsignedWorkdays.length + this.unsignedBonus.length + this.unsignedAdvance.length
      );
    },
  },
  methods: {
    ...mapActions([
      "getAssignedProjects",
      "getDeadlineProjects",
      "getUnsignedWorkdays",
      "getUnsignedBonus",
      "getUnsignedAdvance",
      "getUnsignedLoans",
      "getUnsignedVacations",
      "getRecordingDaysByUser"
    ]),
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
    refreshUnsignedLoans() {
      this.getUnsignedLoans().then((loans) => {
        this.unsignedLoans = loans;
      }).catch((error) => {
        console.log(error)
      })
    },
    refreshUnsignedWorkdays() {
      this.getUnsignedWorkdays().then((workdays) => {
        this.unsignedWorkdays = workdays;
      }).catch((error) => {
        console.log(error);
      })
    },
    refreshUnsignedBonus() {
      this.getUnsignedBonus().then((bonus) => {
        this.unsignedBonus = bonus;
      }).catch((error) => {
        console.log(error);
      })
    },
    refreshUnsignedAdvance() {
      this.getUnsignedAdvance().then((advance) => {
        this.unsignedAdvance = advance;
      }).catch((error) => {
        console.log(error);
      })
    },
    refreshUnsignedVacations() {
      this.getUnsignedVacations().then((vacations) => {
        this.unsignedVacations = vacations
      }).catch((error) => {
        console.log(error)
      })
    },
    refresh() {
      if (this.$store.state.accessLevel > 9) {
        this.refreshUnsignedLoans();
        this.refreshUnsignedWorkdays();
        this.refreshUnsignedBonus();
        this.refreshUnsignedAdvance();
        this.refreshUnsignedVacations();
      }

      this.getAssignedProjects().then((projects) => {
        this.assignedProjects = projects;
      }).catch((error) => {
        console.log(error)
      })

      this.getDeadlineProjects().then((projects) => {
        this.deadlineProjects = projects
      }).catch((error) => {
        console.log(error)
      })

      this.getRecordingDaysByUser().then((days) => {
        this.recordingDays = days
      }).catch((error) => {
        console.log(error)
      })
    },
  },
  mounted() {
    this.refresh();

    this.$refs.calendar.personal = true;
  },
};
</script>

<style lang="scss" scoped>
.staff-row {
  grid-auto-columns: 1fr 1fr 1fr !important;
  gap: 10px;
}

.panel-right {
  position: absolute;
  height: unset;
}

.panel-content-left {
  min-height: 1464px;
}
</style>
