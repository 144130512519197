<template>
  <div
    v-if="event"
    :title="event.title"
    class="h-stack h-start gap-1 calendareventline"
    :style="'background-color:' + getEventTypeData().color"
    @click="handleClick($event.target, event)"
  >
    <i
      class="calendareventline-noevents"
      style="margin-top: 1px"
      :class="getEventTypeData().icon"
    ></i>
    <div
      class="calendareventline-noevents"
      v-if="
        (event.endDate == null ||
          event.startDate.isSame(event.endDate, 'day')) &&
        event.eventType != 'birthday'
      "
    >
      {{ event.startDate.format("HH:mm") }}
    </div>
    <div class="text-left calendareventline-noevents">{{ event.title }}</div>
  </div>
</template>

<script>
import utils from "@/utils.js";
import EventBus from "@/eventbus.js";

export default {
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  methods: {
    getEventTypeData() {
      return utils.calendarObject.eventTypes[this.event.eventType];
    },
    handleClick(element, event) {
      EventBus.$emit("calendareventselected", {
        element: element,
        event: event,
      });
    },
  },
};
</script>

<style lang="scss">
.calendareventline {
  cursor: pointer;
  user-select: none;
}

.calendareventline-noevents {
  pointer-events: none;
}
</style>
