<template>
  <div class="card dynamic search v-start">
    <div class="header h-stretch">
      <input ref="searchBar" placeholder="start typing to search" v-model="query" @input="inputChanged()" type="text" />
    </div>
    <div v-if="hasResult" class="content h-stretch scroll-y v-start">
      <div class="v-stack v-start gap-3 h-stretch">
        <a v-if="resultClients.length > 0" class="text-left">Clients</a>
        <div v-for="client in resultClients" :key="client._id" class="text-left link"
          @click="navigateTo('/clients/detail/' + client._id)">
          {{ client.name }}
        </div>
        <a v-if="resultProjects.length > 0" class="text-left">Projects</a>
        <div v-for="project in resultProjects" :key="project._id" class="text-left link"
          @click="navigateTo('/projects/detail/' + project._id)">
          {{ project.client.name }} - {{ project.title }}
          {{ project.subtitle }}
        </div>
        <a v-if="resultUsers.length > 0" class="text-left">Users</a>
        <div v-for="user in resultUsers" :key="user._id" class="text-left link"
          @click="navigateTo('/users/detail/' + user._id)">
          {{ user.firstName }} {{ user.lastName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import EventBus from "@/eventbus.js";

export default {
  data() {
    return {
      resultProjects: [],
      resultClients: [],
      resultUsers: [],
      query: "",
      projects: null,
      clients: null,
    };
  },
  computed: {
    hasResult() {
      if (this.resultProjects.length > 0) {
        return true;
      }
      if (this.resultClients.length > 0) {
        return true;
      }
      if (this.resultUsers.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["getProjects", "getClients", "getUsers"]),
    refresh() {
      this.$nextTick(() => {
        this.$refs.searchBar.focus();
      });

      this.query = "";

      this.resultProjects = [];
      this.resultClients = [];
      this.resultUsers = [];

      this.getProjects()
        .then((projects) => {
          this.projects = projects;
        })
        .catch((error) => {
          console.log(error);
        });

      this.getClients()
        .then((clients) => {
          this.clients = clients;
        })
        .catch((error) => {
          console.log(error);
        });

      this.getUsers()
        .then((users) => {
          this.users = users;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    inputChanged() {
      // if empty, return nothing
      if (this.query.length == 0) {
        this.resultProjects = [];
        this.resultClients = [];
        this.resultUsers = [];
        return;
      }

      // search projects
      this.resultProjects = this.projects.filter((project) => {
        if (project.title.toLowerCase().includes(this.query.toLowerCase())) {
          return true;
        }
        if (project.subtitle.toLowerCase().includes(this.query.toLowerCase())) {
          return true;
        }
        if (
          project.description.toLowerCase().includes(this.query.toLowerCase())
        ) {
          return true;
        }
        if (
          project.client.name.toLowerCase().includes(this.query.toLowerCase())
        ) {
          return true;
        }
        return false;
      });

      // search users
      this.resultUsers = this.users.filter((user) => {
        if (
          (user.firstName + user.lastName)
            .toLowerCase()
            .includes(this.query.toLowerCase())
        ) {
          return true;
        }
        return false;
      });

      // search clients
      this.resultClients = this.clients.filter((client) => {
        if (client.name.toLowerCase().includes(this.query.toLowerCase())) {
          return true;
        }
        return false;
      });
    },
    navigateTo(path) {
      this.$router.push(path);
      this.$emit("dismiss");
    },
  },
  mounted() {
    EventBus.$on("refreshSearch", () => {
      this.refresh();
    });

    this.refresh();
  },
};
</script>

<style scoped>
.search {
  top: 30px;
  position: absolute;
  height: fit-content;
  max-height: calc(100vh - 90px);
  width: 80vw;
}

.header {
  border: none;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>