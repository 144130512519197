import Vue from 'vue'

export default {
    actions: {
        getXFilesItems({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/xfiles/item").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getXFilesItem({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/xfiles/item/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addXFilesItem({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/xfiles/item", data).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        updateXFilesItem({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.patch("/xfiles/item/" + data._id, data).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        removeXFilesItem({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.delete("/xfiles/item/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
    }
}