<template>
  <div class="v-stack">
    <div class="h-stack h-space-between">
      <div class="h-stack">
        <div
          class="
            v-stack
            calendar-month-picker-link calendar-month-picker-padding
          "
          @click="advanceMonth(-1)"
        >
          <i class="fas fa-angle-left"></i>
        </div>
        <div
          class="
            v-stack
            calendar-month-picker-link calendar-month-picker-padding
          "
          @click="advanceMonth(1)"
        >
          <i class="fas fa-angle-right"></i>
        </div>
        <div
          class="calendar-month-picker-padding calendar-month-picker-link"
          @click="editMonth()"
        >
          {{ viewDate.format("MMMM") }}
        </div>
      </div>
      <div class="h-stack">
        <div
          class="calendar-month-picker-padding calendar-month-picker-link"
          @click="editYear()"
        >
          {{ viewDate.format("YYYY") }}
        </div>
        <div
          class="
            v-stack
            calendar-month-picker-link calendar-month-picker-padding
          "
          @click="advanceYear(-1)"
        >
          <i class="fas fa-angle-left"></i>
        </div>
        <div
          class="
            v-stack
            calendar-month-picker-link calendar-month-picker-padding
          "
          @click="advanceYear(1)"
        >
          <i class="fas fa-angle-right"></i>
        </div>
      </div>
    </div>
    <CalendarMonth
      class="calendarmonthpicker-container"
      v-bind:class="edit ? 'calendarmonthpicker-hidden' : ''"
      :month="viewDate.month()"
      :year="viewDate.year()"
      :highlightToday="highlightToday"
      :date="date"
      @datechange="setDate($event)"
      @dateselected="$emit('dateselected')"
      :events="events"
    >
      <CalendarDataPicker
        v-if="edit"
        class="calendarmonthpicker-data"
        @itemselected="finishEdit($event)"
        :data="pickerData"
        :selectedItem="pickerSelected"
      ></CalendarDataPicker>
    </CalendarMonth>
  </div>
</template>

<script>
import CalendarMonth from "@/components/calendar/CalendarMonth.vue";
import CalendarDataPicker from "@/components/calendar/CalendarDataPicker.vue";
import moment from "moment";

export default {
  data() {
    return {
      edit: false,
      editingMonth: false,
      pickerData: [],
      pickerSelected: null,
      viewDate: moment(),
    };
  },
  components: {
    CalendarMonth,
    CalendarDataPicker,
  },
  props: {
    date: {
      type: Object,
      default: () => moment(),
    },
    highlightToday: {
      type: Boolean,
      default: true,
    },
    events: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    date() {
      this.viewDate = moment(this.date);
    },
  },
  model: {
    prop: "date",
    event: "datechange",
  },
  methods: {
    resetEdit() {
      this.edit = false;
      this.viewDate = moment(this.date);
    },
    editYear() {
      if (this.editingMonth && this.edit) {
        this.editingMonth = false;
      } else {
        this.edit = !this.edit;
        this.editingMonth = false;
      }

      if (this.edit) {
        this.pickerData = [];
        for (let i = 2000; i <= moment().year(); i++) {
          this.pickerData.push(i);
        }
        this.$nextTick(() => {
          this.pickerSelected = this.date.year();
        });
      }
    },
    editMonth() {
      if (!this.editingMonth && this.edit) {
        this.editingMonth = true;
      } else {
        this.edit = !this.edit;
        this.editingMonth = true;
      }

      if (this.edit) {
        this.pickerData = [];
        for (let i = 0; i < 12; i++) {
          this.pickerData.push(moment().month(i).format("MMMM"));
        }
        this.$nextTick(() => {
          this.pickerSelected = this.date.format("MMMM");
        });
      }
    },
    finishEdit(item) {
      this.edit = false;
      if (this.editingMonth) {
        this.viewDate.month(item);
      } else {
        this.viewDate.year(item);
        this.$emit("yearchange", this.viewDate.year());
      }
    },
    advanceMonth(amount) {
      this.viewDate.add(amount, "months");
      this.$forceUpdate();
    },
    advanceYear(amount) {
      this.viewDate.add(amount, "years");
      this.$forceUpdate();
      this.$emit("yearchange", this.viewDate.year());
    },
    setDate(date) {
      this.$emit("datechange", date);
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-month-picker-padding {
  padding: 6px;
}

.calendar-month-picker-link {
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
}

.calendarmonthpicker-container {
  position: relative;
}

.calendarmonthpicker-data {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1 !important;
}
</style>

<style>
.calendarmonthpicker-hidden > div {
  opacity: 0;
}
</style>
