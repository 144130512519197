export default [
  {
    path: "/lifefinance",
    name: "lifeFinance",
    component: () => import("../views/finance/lifeFinance.vue"),
    children: [
      {
        path: "expenses",
        component: () => import("../views/finance/FinanceExpenses.vue"),
      },
      {
        path: "income",
        component: () => import("../views/finance/FinanceIncome.vue"),
      },
      {
        path: "overview",
        component: () => import("../views/finance/FinanceOverview.vue"),
      },
      {
        path: "crewpay",
        component: () => import("../views/finance/FinanceCrewPay.vue"),
      },
      {
        path: "crewhours",
        component: () => import("../views/finance/FinanceCrewHours.vue"),
      },
      {
        path: "loans",
        component: () => import("../views/finance/FinanceLoans.vue"),
      },
      {
        path: "gear",
        component: () => import("../views/finance/FinanceGear.vue"),
      },
    ],
  },
  {
    path: "/finance/expenses/add",
    name: "Add Expense",
    component: () => import("../views/finance/AddEditExpense.vue"),
  },
  {
    path: "/finance/loans/add/:id",
    name: "New Loan",
    props: true,
    component: () => import("../views/finance/NewLoan.vue"),
  },
  {
    path: "/finance/loans/add",
    name: "Add Loan",
    component: () => import("../views/finance/NewLoan.vue"),
  },
  {
    path: "/finance/expenses/edit/:id",
    name: "Edit Expense",
    component: () => import("../views/finance/AddEditExpense.vue"),
    props: true,
  },
  {
    path: "/finance/expenses/add/gear",
    name: "Add Gear/Expense",
    component: () => import("../views/finance/AddGearExpense.vue"),
    props: true,
  },
  {
    path: "/loans/detail/:id",
    name: "Loan Detail",
    component: () => import("../views/finance/LoanDetail.vue"),
    props: true,
  },
];
