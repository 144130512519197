const moment = require("moment");

const pricelistObject = {
  servicesPrice(pricelist) {
    let value = 0;
    for (const item of pricelist.items.filter(
      (item) => item.item.categoryMain == "PERSONÁL / SLUŽBY"
    )) {
      value += item.price * item.count;
    }
    return value;
  },
  gearPrice(pricelist) {
    let value = 0;
    for (const item of pricelist.items.filter(
      (item) => item.item.categoryMain == "TECHNIKA"
    )) {
      value += item.price * item.count;
    }
    return value;
  },
  rawPrice(pricelist) {
    let value = 0;
    for (const item of pricelist.items) {
      value += item.price * item.count;
    }
    return value;
  },
  discount(pricelist) {
    return Math.round(
      -(
        (pricelistObject.gearPrice(pricelist) / 100) *
        pricelist.discountPercent
      )
    );
  },
  productionFee(pricelist) {
    return Math.round(
      (pricelistObject.rawPrice(pricelist) / 100) *
      pricelist.productionFeePercent
    );
  },
  totalPrice(pricelist) {
    return Math.round(
      pricelistObject.rawPrice(pricelist) +
      pricelistObject.productionFee(pricelist) +
      pricelistObject.discount(pricelist)
    );
  },
  vatPrice(pricelist) {
    return Math.round(
      (pricelistObject.totalPrice(pricelist) / 100) * pricelist.vat
    );
  },
  totalPriceWithVat(pricelist) {
    return Math.round(
      pricelistObject.totalPrice(pricelist) +
      pricelistObject.vatPrice(pricelist)
    );
  },
};

exports.pricelist = pricelistObject;

const financeObject = {
  workdayHours(workdays) {
    let hours = 0;
    for (const workday of workdays) {
      for (const workhour of workday.workhours) {
        hours = hours + workhour.hours;
      }
    }
    return hours;
  },
  revenue(workdays, oneTimePay) {
    let revenue = 0;
    for (const workday of workdays) {
      for (const workhour of workday.workhours) {
        revenue = revenue + workhour.hours * workhour.pay.amount;
      }
    }

    for (const singleOneTimePay of oneTimePay) {
      if (singleOneTimePay.fine || singleOneTimePay.amount > 0) {
        revenue = revenue + singleOneTimePay.amount;
      }
    }
    return Math.round(revenue);
  },
  advance(oneTimePay) {
    let advance = 0;
    for (const singleOneTimePay of oneTimePay) {
      if (singleOneTimePay.fine != true && singleOneTimePay.amount < 0) {
        advance = advance + singleOneTimePay.amount;
      }
    }
    return Math.abs(Math.round(advance));
  },
};

exports.finance = financeObject;

const loanObject = {
  amountPaid(loan) {
    let amount = 0;
    for (const payment of loan.onetimepay) {
      if (payment.state != "canceled") {
        amount += Math.abs(payment.amount);
      }
    }
    return amount;
  },
  leftToPay(loan) {
    return Math.max(loan.amount - this.amountPaid(loan), 0);
  },
  predictedEndDate(loan) {
    const amount = this.leftToPay(loan);
    const months = Math.ceil(amount / loan.monthlyRate);
    const endDate = moment(loan.startDate).date(15).add(months, "M");
    return {
      endDate: endDate,
      duration: months,
    };
  },
};

exports.loanObject = loanObject;

const formatObject = {
  telephone(number) {
    if (String(number) == "null") {
      return "";
    }
    return String(number)
      .replace(/(\d{3})/g, "$1 ")
      .replace(/(^\s+|\s+$)/, "");
  },
  currency(number) {
    if (String(number) == "null") {
      return "";
    }

    let reversed = String(number).split("").reverse().join("");
    let prefix = null;
    if (reversed.includes(".")) {
      prefix = String(number).split(".")[1];
      reversed = String(number).split(".")[0].split("").reverse().join("");
    }

    let result = reversed.replace(/(.{3})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
    const final = result.split("").reverse().join("").trim();

    return prefix ? final + "." + prefix : final;
  },
};

exports.format = formatObject;

const carRentalObject = {
  price(car, trip, gasPrice) {
    const fuel = (car.consumption / 100) * trip.km * gasPrice;
    const depriciation = car.useRatePerKm * trip.km;

    return fuel + depriciation;
  },
};

exports.carRentalObject = carRentalObject;

const calendarObject = {
  getDayEvents(date, events) {
    let filteredEvents = []

    if (date.year().toString() in events) {
      const yearObject = events[date.year().toString()]
      if (date.month().toString() in yearObject) {
        const monthObject = yearObject[date.month().toString()]
        if (date.date().toString() in monthObject) {
          const array = monthObject[date.date().toString()]
          if (Array.isArray(array)) {
            filteredEvents = filteredEvents.concat(array)
          }
        }
      }
    }

    return filteredEvents
  },
  eventTypes: {
    generic: { icon: "far fa-calendar", color: "rgb(110, 120, 255)" },
    production: { icon: "fas fa-video", color: "#2c3e50" },
    postproduction: { icon: "fas fa-film", color: "orange" },
    deadline: { icon: "fas fa-skull-crossbones", color: "#e45343" },
    vacation: { icon: "fas fa-plane", color: "#42b983" },
    birthday: { icon: "fas fa-birthday-cake", color: "lightskyblue" },
  },
};

exports.calendarObject = calendarObject;
