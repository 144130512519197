<template>
  <div class="crew-icon">
    <div class="initials">
      {{ user.firstName[0] + user.lastName[0] }}
    </div>
    <div class="tooltip">
      {{ user.fullName }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.sm {
  width: 25px !important;
  height: 25px !important;
}

.crew-icon {
  margin: 6px;
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 50%;
  border: 1px solid grey;
  background-color: white;
  color: black;
}

.crew-icon:hover {
  background-color: grey;
  cursor: pointer;
}

.crew-icon > .initials {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.crew-icon > .tooltip {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -100%);
  background-color: grey;
  color: white;
  border-radius: 6px;
  padding: 6px;
  transition: opacity 0.3s;
  opacity: 0;
  pointer-events: none;
}

.crew-icon .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid grey 10px;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: -10px;
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
}

.crew-icon:hover > .tooltip {
  opacity: 1;
}
</style>