import Vue from 'vue'

export default {
    actions: {
        addLoan({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/loans/add", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getUserLoans({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/loans/user").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getUnsignedLoans({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/loans/unsigned").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getLoans({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/loans").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getLoan({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/loans/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        signLoan({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.patch("/loans/sign/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        markLoanAsPaid({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.patch("/loans/paid/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        cancelLoan({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                Vue.api.patch("/loans/cancel/" + id).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addPay({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/pay/add", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        addOneTimePay({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/onetimepay/add", data).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getOneTimePay({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/onetimepay/").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getUnsignedBonus({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/onetimepay/unsignedBonus").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        getUnsignedAdvance({ dispatch }) {
            return new Promise((resolve, reject) => {
                Vue.api.get("/onetimepay/unsignedAdvance").then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        signOneTimePay({ dispatch }, oneTimePay) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/onetimepay/sign", {
                    oneTimePay: oneTimePay
                }).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
        cancelOneTimePay({ dispatch }, oneTimePay) {
            return new Promise((resolve, reject) => {
                Vue.api.post("/onetimepay/cancel", {
                    oneTimePay: oneTimePay
                }).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    dispatch("handle_response_error", error)
                    reject(error.response)
                })
            })
        },
    }
}