var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-stack h-stretch gap-3"},[_c('Table',{attrs:{"items":_vm.customLoans,"css":'80px 80px 80px 80px 100px 1fr 100px',"columns":[
      {
        name: 'Start Date',
        sort: 'date',
        formatType: 'date',
        format: 'DD.MM.YYYY',
        path: ['startDate'],
      },
      {
        name: 'Amount',
        sort: 'numeric',
        path: ['amount'],
        format: 'czk',
        formatType: 'currency',
      },
      {
        name: 'Monthly',
        sort: 'numeric',
        path: ['monthlyRate'],
        format: 'czk',
        formatType: 'currency',
      },
      {
        name: 'Length',
        sort: 'numeric',
        path: ['predictedEndDate', 'duration'],
        format: ' months',
      },
      {
        name: 'Last Payment',
        sort: 'date',
        formatType: 'date',
        format: 'DD.MM.YYYY',
        path: ['predictedEndDate', 'endDate'],
      },
      {
        name: 'Description',
        sort: 'alphabet',
        path: ['description'],
      },
      {
        name: 'Signed',
        alignTitle: 'center',
      } ]},scopedSlots:_vm._u([{key:"Signed",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"h-stack h-center"},[(item.state == 'signed')?_c('div',[_c('i',{staticClass:"fas fa-check fa-lg text-success"})]):_vm._e(),(item.state == 'canceled')?_c('div',[_c('i',{staticClass:"fas fa-times fa-2x text-error"})]):_vm._e(),(item.state == 'created')?_c('div',{staticClass:"h-stack gap-5"},[_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.cancel(item._id)}}},[_c('i',{staticClass:"fas fa-times fa-2x text-error"})]),_c('div',{staticClass:"ma",on:{"click":function($event){$event.stopPropagation();return _vm.sign(item._id)}}},[_c('i',{staticClass:"fas fa-check fa-lg text-success"})])]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }