<template>
  <div class="container">
    <div class="calendarviewyear-title">
      <div class="h-stack h-space-between">
        <div class="link" @click="addYear(-1)">
          <i class="fas fa-angle-left"></i>
        </div>
        <div class="calendarviewyear-title-year">{{ year }}</div>
        <div class="link" @click="addYear(1)">
          <i class="fas fa-angle-right"></i>
        </div>
      </div>
    </div>
    <div class="v-stack gap-3 item" v-for="month in months" :key="month.title">
      <div class="link" @click="monthClick(month.month)">
        {{ month.title }}
      </div>
      <CalendarMonth
        class="calendarviewyear-month"
        :date="date"
        @datechange="$emit('datechange', $event)"
        @dateselected="$emit('dateselected', $event)"
        :year="month.year"
        :month="month.month"
        :showOverflow="false"
        :highlightSelected="false"
        :events="events"
      ></CalendarMonth>
    </div>
  </div>
</template>

<script>
import CalendarMonth from "@/components/calendar/CalendarMonth.vue";
import moment from "moment";

export default {
  components: {
    CalendarMonth,
  },
  props: {
    date: {
      type: Object,
      default: () => moment(),
    },
    year: {
      type: Number,
      default: moment().year(),
    },
    events: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  model: {
    prop: "date",
    event: "datechange",
  },
  methods: {
    monthClick(month) {
      const date = moment(this.date).month(month).date(1);
      this.$emit("datechange", date);
      this.$emit("monthchange");
    },
    addYear(amount) {
      this.$emit("datechange", moment(this.date).add(amount, "years"));
    },
  },
  computed: {
    months() {
      const data = [];
      for (let i = 0; i < 12; i++) {
        const date = moment(this.date).month(i);
        data.push({
          year: this.year,
          month: date.month(),
          title: date.format("MMMM"),
        });
      }
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.calendarviewyear-title {
  position: fixed;
  width: calc(100% - 520px - 40px);
  margin-top: -92px;
  padding-top: 50px;
  padding-bottom: 12px;
  background-color: white;
  border-bottom: 1px lightgray solid;
  z-index: 3;
}

.calendarviewyear-title-year {
  min-width: 160px;
}

html:not([data-scroll="0"]) .calendarviewyear-title {
  border-bottom: 2px lightgray solid;
}

.container {
  padding-top: 42px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 10px;
}

.item {
  width: fit-content;
  height: fit-content;
  justify-self: center;
}

.link {
  user-select: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.calendarviewyear-month {
  border: 1px solid lightgray;
}
</style>
