var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-stack h-stretch gap-3"},[_c('div',{staticClass:"table v-start",style:(_vm.height ? ("height: " + _vm.height + "px;") : '')},[_c('div',{staticClass:"table-header row",style:(_vm.styleRowHeader())},_vm._l((_vm.columns),function(title){return _c('div',{key:title.name,class:[
          title.alignTitle ? '' : 'text-left',
          title.formatType == 'currency' ? 'currency' : '' ],style:([
  title.alignTitle ? 'text-align: ' + title.alignTitle : '' ]),on:{"click":function($event){return _vm.sort(title)}}},[_vm._v(" "+_vm._s(title.name)+" "),(_vm.selectedTitle == title && _vm.ascending)?[_vm._v("▼")]:_vm._e(),(_vm.selectedTitle == title && !_vm.ascending)?[_vm._v("▲")]:_vm._e()],2)}),0),_vm._l((_vm.sortedItems.slice(
        _vm.dynamicLimit * _vm.page,
        _vm.dynamicLimit * _vm.page + _vm.dynamicLimit
      )),function(item){return _c('div',{key:item._id,staticClass:"table-row row",class:[typeof item.tableColumns == 'object' ? 'auto-height' : ''],style:(_vm.styleRow(item))},_vm._l((_vm.columns),function(title){return _c('div',{key:title.name,staticClass:"text-left",class:[
            title.formatType == 'link' ? 'link' : '',
            title.formatType == 'copy' ? 'copy' : '',
            title.formatType == 'currency' ? 'currency' : '',
            typeof item.tableColumns == 'object' ? 'auto-height' : ''
          ],style:(title.styleFunction ? title.styleFunction(item) : ''),on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"cancel",undefined,$event.key,undefined)){ return null; }return _vm.handleClick(item, title)}}},[_vm._t(title.name,null,{"item":item}),_vm._v(" "+_vm._s(_vm.getItemValue(title, item))+" "),(typeof item.tableColumns == 'object')?_vm._l((item.tableColumns[title.name]),function(column){return _c('div',{key:column._id,staticClass:"sub-row"},[_vm._v(_vm._s(column.text)+" ")])}):_vm._e()],2)}),0)}),(_vm.sortedItems.length == 0)?_c('div',{staticClass:"table-row"},[_vm._v("No Data")]):_vm._e()],2),(_vm.pageable)?_c('div',{staticClass:"h-stack gap-3 columns-3"},[_c('div',{staticClass:"h-stack gap-5"},[_c('div',{on:{"click":function($event){return _vm.goToPage(0)}}},[_c('i',{staticClass:"fas fa-angle-double-left",class:[_vm.page == 0 ? 'disabled' : '']})]),_c('div',{on:{"click":function($event){return _vm.previousPage()}}},[_c('i',{staticClass:"fas fa-arrow-left",class:[_vm.page == 0 ? 'disabled' : '']})]),_vm._l((Math.min(_vm.numberOfPages + 1, _vm.maxPageShortcut)),function(index){return _c('div',{key:index,class:[
          index - 1 + _vm.pageOffset == _vm.page ? 'active-page' : 'page-link' ],staticStyle:{"width":"20px"},on:{"click":function($event){return _vm.goToPage(index - 1 + _vm.pageOffset)}}},[_vm._v(" "+_vm._s(index + _vm.pageOffset)+" ")])}),_c('div',{on:{"click":function($event){return _vm.nextPage()}}},[_c('i',{staticClass:"fas fa-arrow-right",class:[_vm.page == _vm.numberOfPages ? 'disabled' : '']})]),_c('div',{on:{"click":function($event){return _vm.goToPage(_vm.numberOfPages)}}},[_c('i',{staticClass:"fas fa-angle-double-right",class:[_vm.page == _vm.numberOfPages ? 'disabled' : '']})])],2),_c('div')]):_vm._e(),(_vm.expandable && this.sortedItems.length > this.limit)?_c('div',{staticClass:"ma table-header",on:{"click":function($event){return _vm.handleExpand()}}},[(!_vm.expanded)?[_vm._v("More ▼")]:_vm._e(),(_vm.expanded)?[_vm._v("Less ▲")]:_vm._e()],2):_vm._e(),(_vm.showCount)?_c('div',[_vm._v("Count: "+_vm._s(_vm.sortedItems.length))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }