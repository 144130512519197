const moment = require("moment")

exports.logCategories = ["project"];

exports.folderTypes = ["master", "backup", "archive"];

exports.driveTypes = ["SSD", "HDD", "PCIe", "M.2"];

exports.projectState = [
  "created",
  "budget",
  "planned",
  "recorded",
  "postproduction",
  "revision",
  "finished",
  "canceled",
];

exports.projectIcons = [
  "universal",
  "stream",
  "promo",
  "speech",
  "film",
  "music",
  "party",
  "sport",
  "commercial",
  "aerial",
  "tv series",
  "foto",
];

exports.monthlyrates = [
  10000, 11000, 12000, 13000, 14000, 15000, 16000, 17000, 18000, 19000, 20000,
  21000, 22000, 23000, 24000, 25000, 26000, 27000, 28000, 29000, 30000, 31000,
  32000, 33000, 34000, 35000, 36000, 37000, 38000, 39000, 40000, 41000, 42000,
  43000, 44000, 45000, 46000, 47000, 48000, 49000, 50000, 51000, 52000, 53000,
  54000, 55000, 56000, 57000, 58000, 59000, 60000, 61000, 62000, 63000, 64000,
  65000, 66000, 67000, 68000, 69000, 70000, 71000, 72000, 73000, 74000, 75000,
  76000, 77000, 78000, 79000, 80000, 81000, 82000, 83000, 84000, 85000, 86000,
  87000, 88000, 89000, 90000, 91000, 92000, 93000, 94000, 95000, 96000, 97000,
  98000, 99000, 100000,
];

exports.pricelistMainCategories = ["PERSONÁL / SLUŽBY", "TECHNIKA"];
exports.pricelistSubCategoriesServices = [
  "Preprodukce",
  "Produkce",
  "Postprodukce",
];
exports.pricelistSubCategoriesGear = [
  "Kamery",
  "Objektivy",
  "Příislušenství k objekt.",
  "Vysílání / Stream",
  "Audio",
  "Světla",
  "Camera Rig",
  "Monitory / Rekordéry",
  "Studio",
  "Komunikace",
  "Doprava",
  "Další",
];

exports.xfilesCategories = [
  "software",
  "hosting",
  "phone/data",
  "eshop hardware",
  "product accounts",
  "eshop software",
  "socials",
  "email",
  "admin banka",
  "admin email",
  "admin dalsi",
  "dalsi",
];

exports.pricelistUnits = [
  {
    name: "kus / den",
  },
  {
    name: "hod",
    value: 1,
  },
  {
    name: "1/2 den",
    value: 4,
  },
  {
    name: "den",
    value: 8,
  },
  {
    name: "do 2 hod",
    value: 2,
  },
  {
    name: "2-8 hod",
    value: 8,
  },
  {
    name: "litr",
  },
  {
    name: "metr",
  },
  {
    name: "km",
  },
  {
    name: "A4",
  },
  {
    name: "okno",
  },
  {
    name: "akce",
  },
];

exports.notificationStates = [
  "unread",
  "read"
]

exports.notificationTypes = [
  "newPay"
]

exports.notificationObjects = {
  "newPay": {
    text: (data, instigator) => {
      return `${instigator.fullName} set a new pay for you starting ${moment(data.date).format("DD.MM.YYYY")}`
    },
    link: (data, instigator) => {
      data
      instigator
      return '/account/pay'
    }
  }
}