import Vue from "vue";

export default {
  actions: {
    getLogs({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        let string = "";

        if (data.category) {
          string = string + "?category=" + data.category;
        }

        if (data.reference) {
          string = string + "&reference=" + data.reference;
        }

        Vue.api
          .get("/log/123" + string)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            dispatch("handle_response_error", error);
            reject(error.response);
          });
      });
    },
  },
};
